const ROUTES = {
  RESTAURANT_LANDING: {
    path: '/restaurant/:slug',
    name: 'restaurant',
    defaultRedirect: '/restaurant/ashland-hill',
    link_path: (slug) => (`/restaurant/${slug}`),
  },
  ORDER: {
    path: '/order/:slug',
    link_path: (slug) => (`/order/${slug}`),
    child: {
      MENU: {
        path: 'menu',
        name: 'menu',
      },
      CHECKOUT: {
        path: 'checkout',
        name: 'checkout',
      },
      TANK_YOU: {
        path: 'thank-you/:orderId',
        name: 'thank-you',
        link_path: (orderId) => (`thank-you/${orderId}`),
      },
    },
    name: 'order',
  },
  AUTH: {
    path: '/auth',
    child: {
      RESET_PASSWORD: {
        path: 'reset-password',
        name: 'reset-password',
      },
      RESET_PASSWORD_SENT: {
        path: 'reset-password-sent',
        name: 'reset-password-sent',
      },
      SIGN_IN: {
        path: 'login',
        name: 'login',
      },
      SIGN_UP: {
        path: 'register',
        name: 'register',
      },
      ADD_PHONE: {
        path: 'add-phone',
        name: 'add-phone',
      },
      ADD_PHONE_VERIFY: {
        path: 'add-phone-verify',
        name: 'add-phone-verify',
      },
    },
    name: 'auth',
  },
  PROFILE: {
    path: '/profile',
    child: {
      UPDATE: {
        path: 'update',
        name: 'update',
      },
    },
  },
  ID_VERIFICATION: {
    path: '/id-verification/:orderId',
    name: 'id-verification',
    link_path: (orderId) => (`/id-verification/${orderId}`),
  },
  NOT_FOUND: {
    path: '/404',
    name: '404',
  }
};

export default ROUTES;
import { put, select } from "redux-saga/effects";
import { push } from "connected-react-router";
import ROUTES from '../../../routes';
import { getAuthSuccessRouter } from '../../selectors/routers';
import { FETCH_GEN_ADDRESS_SAGA } from '../../actions/actionTypes';

export const HandleSuccessRouteRedirect = function* HandleSuccessRouteRedirect(successRouter) {
  /* redirect to authSuccessRouter - refference if exist */
  // set manual successRouter or get from router state
  const successRouterRedirect = yield successRouter || select(getAuthSuccessRouter);
  const restaurantSlug = yield select(state => state.root.restaurantSlug);
  if (successRouterRedirect) {
    yield put(push(successRouterRedirect));
  } else {
    yield put(push(`${ ROUTES.ORDER.link_path(restaurantSlug) }/${ ROUTES.ORDER.child.MENU.path }`));
  }
};

export const handleAddUserAddressToDelivery  = function* handleAddUserAddressToDelivery(addressObj, successRouter) {
  /*
   set default delivery address from user profile logic
  */
  if (addressObj) {
    const genAddress = yield select(state => state.delivery.genAddress);
    const slug = yield select(state => state.root.restaurantSlug);
    if (!genAddress.address1) {
      const addressPayload = {
        ...addressObj,
        lat: addressObj.lat,
        lng: addressObj.lng,
        slug,
      };
      yield put({type: FETCH_GEN_ADDRESS_SAGA, payload: addressPayload});
    }
    successRouter = `${ROUTES.ORDER.link_path(slug)}/${ROUTES.ORDER.child.MENU.path}`;
    return `${ROUTES.ORDER.link_path(slug)}/${ROUTES.ORDER.child.MENU.path}`;
  }
  return successRouter;
};

import { takeEvery, put, select, call } from 'redux-saga/effects';

import OrderRepository from '../../repositories/order';
import BaseRepositoryError from '../../repositories/base.errors';
import { addressForOrderApi } from '../../selectors/delivery';
import { userDataForOrderApi } from '../../selectors/user';
import { menuItemsForOrderApi } from '../../selectors/order';

import {
  FETCH_DATA_404,
  FETCH_ORDER_CART_TOTAL_PROMO_SAGA, FETCH_ORDER_CART_TOTAL_SAGA,
  SET_DATA_ERROR_MSG,
  SET_ORDER_CART_TOTAL,
} from '../../actions/actionTypes';


function* workerOrderCartTotalPromo(action) {
  // user data
  // delivery data
  // delivery data
  const addressData = yield select(addressForOrderApi);
  const userData = yield select(userDataForOrderApi);
  const menuItemsData = yield select(menuItemsForOrderApi);
  const { id }  = yield select(state => state.restaurant.restaurantData);
  const payloadsData = {
    supplier_id: id,
    promo_code: action.payload && action.payload.promoCode,
  };
  try {
    // fetch updated cart total
    const response = yield call(OrderRepository.cartWithPromo, { addressData, userData, menuItemsData, payloadsData });
    const cartTotal = yield call(OrderRepository.cart, {addressData, userData, menuItemsData, payloadsData});
    if (response.promo_info && response.promo_info.valid) {
      if (cartTotal) {
        const { final_price } = cartTotal;
        const updatedDiscount = response.promo_info.discount;
        if (final_price && updatedDiscount) {
          const updatedFinalPrice = final_price - updatedDiscount;
          yield put ({ type: SET_ORDER_CART_TOTAL,
              payload: { ...cartTotal, ...{ final_price: updatedFinalPrice, discount: updatedDiscount } }
          });
        }
      }
    } else {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: response.error_message || "Promo code is not valid" });
    }
    // TODO ADD logic
    // yield put ({ type: SET_ORDER_CART_TOTAL, payload: response });
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
    }
  }
}

export default function* watchOrderCartTotalPromo() {
  yield takeEvery(FETCH_ORDER_CART_TOTAL_PROMO_SAGA, workerOrderCartTotalPromo);
}

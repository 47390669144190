import MobileCart from './MobileCart';
import DesktopCart from './DesktopCart';

const Cart = (props) => {
  const { isMobile } = props;
  return (
    <>
      { isMobile ?
        <MobileCart { ...props } /> :
        <DesktopCart  { ...props } />
      }
    </>
  );
};

export default Cart;
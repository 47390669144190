import {
  SET_STRIPE_CLIENT_SECRET,
  SET_STRIPE_ERROR_MSG,
} from '../../actions/actionTypes';

export const orderPlacedInitialState = {
  stripeClientSecret: null, // doesn't use now
  stripeError: null,
  currency: 'usd',
  country: 'US',
};

export default function setUserData(state = orderPlacedInitialState, action) {
  switch (action.type) {
    case SET_STRIPE_CLIENT_SECRET: // doesn't use now
      return {
        ...state,
        stripeClientSecret: action.payload,
      };
    case SET_STRIPE_ERROR_MSG:
      return {
        ...state,
        stripeError: action.payload,
      };
    default:
      return state;
  }
}
import {all, fork} from "redux-saga/effects";

import watchUpdateRestrictedOrderData from './watchUpdateRestrictedOrderData';

function* rootRestricted() {
  yield all([
    fork(watchUpdateRestrictedOrderData),
  ]);
}

export default rootRestricted;

import { put, takeLatest, call } from "redux-saga/effects";
import BaseRepositoryError from '../../repositories/base.errors';
import AuthRepository from '../../repositories/auth';

import {
  FETCH_LOGOUT_SAGA,
  FETCH_DATA_404,
  SET_DATA_ERROR_MSG, SET_USER_DATA,
} from '../../actions/actionTypes';


function* workerFetchLogout(action) {
  try {
    yield call(AuthRepository.logout);
    yield put({ type: SET_USER_DATA, payload: {} });
  } catch (e) {
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* FetchSignUpSaga() {
  yield takeLatest(FETCH_LOGOUT_SAGA, workerFetchLogout);
}

export const addressForOrderApi = (
  state,
  key = "address",
  locationAsArray = false
) => {
  /*
    "address": {
        "address1": "3104 4th St",
        "address2": "Santa Monica, CA 90405, US",
        "location": {
            "lat": 33.9994115,
            "lng": -118.4773178
        },
        "suite": "Apt 101"
    },
  */
  let location;
  if (locationAsArray) {
    location = [state.delivery.genAddress.lng, state.delivery.genAddress.lat];
  } else {
    location = {
      lat: state.delivery.genAddress.lat,
      lng: state.delivery.genAddress.lng,
    };
  }

  return {
    [key]: {
      address1: state.delivery.genAddress.address1,
      address2: state.delivery.genAddress.address2,
      suite: state.delivery.genAddress.suite,
      location,
    },
  };
};

import { useEffect, useRef, useState } from 'react';
import PlacesAutocomplete from '../Form/PlacesAutocomplete';
import { useTranslation } from 'react-i18next';

const LocationWrapper = (props) => {
  const { className, address, fetchGenAddress, isMobile } = props;
  const { t } = useTranslation();
  const clickOutRef = useRef(null);
  const [popoverShow, setPopoverShow ] = useState(false);
  const [isClickOutside, setIsClickOutside ] = useState(false);
  
  const changePopover = () => {
    setPopoverShow(!popoverShow);
  };
  
  const changeNewAddress = (value) => {
    setPopoverShow(!popoverShow);
    fetchGenAddress(value);
  };

  useEffect(() => {
    if (isClickOutside) {
      changePopover(false);
      setIsClickOutside(false);
    }
  }, [isClickOutside]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const limit = 30;
      if (clickOutRef.current) {
        if (event.clientY > (clickOutRef.current.offsetTop + clickOutRef.current.offsetHeight + limit)) {
          setIsClickOutside(true);
        } else if (event.clientX > (clickOutRef.current.offsetWidth + clickOutRef.current.offsetLeft + limit)) {
          setIsClickOutside(true);
        } else if (event.clientX < clickOutRef.current.offsetLeft - limit) {
          setIsClickOutside(true);
        } else if (event.clientY < clickOutRef.current.offsetTop - limit) {
          setIsClickOutside(true);
        }
      }
    };
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popoverShow]);
  
  const PopoverLocation = () => (
    <div className="address__popover_click_cover" ref={clickOutRef}>
      <div className="address__popover">
        <PlacesAutocomplete
          onPlaceGet={changeNewAddress}
          className={"header__address"}
          isAllowClear={true}
          initialValues={ address }
        />
      </div>
    </div>
  );
  
  return (
    <div className={className}>
      <a onClick={ () => changePopover() }>
        { isMobile ?
          <div className="mobile__link_wrapper">
            <span className="text-upper label">{ t('app.header.delivery') }</span>
            <span className="semi-bold">{ address }</span>
          </div> :
          <>
            <span className="coco__icon lg location color_app__icon"/>
            <span>{ address }</span>
          </>
        }
      </a>
      { popoverShow &&
        <PopoverLocation/>
      }
    </div>
  );
};

export default LocationWrapper;
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Spin, message, Col, Select, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import ReactCodeInput from 'react-verification-code-input';

import { FETCH_ADD_PHONE_CODE_VERIFY_SAGA } from '../../redux/actions/actionTypes';

const AddPhoneCodeVerify = () => {
  const CODE_SIZE = 4;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formLayout, setFormLayout] = useState('vertical');
  const [code, setCode] = useState('');
  const [loading, setLoading] = useState(false);
  const { errorMsg } = useSelector(state => state.errors);
  
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (errorMsg) {
      setLoading(false);
    }
  }, [errorMsg]);

  const fetchVerify = useCallback(
    (payload) => dispatch({ type: FETCH_ADD_PHONE_CODE_VERIFY_SAGA,  payload }),
    [dispatch],
  );
  
  const changeCode = (value) => {
    setCode(value);
  };

  const onFinish = () => {
    if (code.length === CODE_SIZE) {
      fetchVerify({code: code});
      // setLoading(true);
    }
  };

  return(
    <div>
      <Spin spinning={loading} indicator={antIcon}>
      <div className="auth_card__wrap mb-2">
        <h1 className="text-center">
          { t('auth.add_phone_verify-header') }
        </h1>
      </div>
      <div className="auth_card__wrap">
        <Form
          className="auth__form"
          layout={formLayout}
          size={'large'}
          initialValues={{
            phoneCode: '+1',
          }}
        >
          <Row justify={'center'} align={'middle'}>
            <ReactCodeInput fields={CODE_SIZE} autoFocus={true} onChange={changeCode}/>
          </Row>
          <Form.Item label=" " colon={false}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              onClick={() => onFinish()}
              className="auth_btn app__btn text-upper" block
            >
              { t('auth.add_phone_verify') }
            </Button>
          </Form.Item>
        </Form>
      </div>
      </Spin>
    </div>
  );
};

export default AddPhoneCodeVerify;
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Input, Spin, Button, Descriptions } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { RestaurantOrderContext } from './context';
import { stripePk, googleMapURL } from '../../configs';

import CardMenuItem from '../../components/Cards/CardMenuItem';
import StripeCardForm from '../../components/Payments/StripeCardForm';
import StripeRequestButtons from '../../components/Payments/StripeRequestButtons';
import FormContactCheckout from './components/FormCheckoutContact';
import CheckoutCartTotalTable from './components/CheckoutCartTotalTable';
import MapWithAMarker from '../../components/Maps/MapWIthMarker';

import ROUTES from '../../routes';
import {
  SET_STRIPE_ERROR_MSG,
  FETCH_DATA_ERROR_MSG,
  FETCH_ORDER_CART_TOTAL_PROMO_SAGA,
  FETCH_ORDER_CART_TOTAL_SAGA,
  FETCH_ORDER_PLACE_SAGA,
  UPDATE_MENU_ITEMS_CART_SAGA,
} from '../../redux/actions/actionTypes';
import { CRUD_ACTIONS } from '../../const';
import { priceFormatter } from '../../utils';
import { Helmet } from 'react-helmet';

export const TheOrderCheckout = () => {
  const LABEL_FOR_PAYS_BTNS = 'COCO delivery order';
  const { slug } = useParams();
  const { fetchGenAddress, menuItemsCart, restaurantData } = useContext(RestaurantOrderContext);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const { isMobile } = useSelector(state => state.options);
  const [ isLoadingPage, setIsLoadingPage ] = useState(false);

  const [ promoCode, setPromoCode ] = useState('');
  const [ contactData, setContactData ] = useState(null);
  const [ isContactSaved, setIsContactSaved ] = useState(false);
  const [ contactError, setContactError ] = useState(false);
  const [ paymentMethod, setPaymentMethod ] = useState(null);
  const [ paymentFinalPrice, setPaymentFinalPrice ] = useState(0); // for keep changes
  const [ isPayedWithBtn, setIsPayedWithBtn ] = useState(false); // for reset when cart sum has been changed
  const [ stripeObj, setStripeObj ] = useState(null);
  const [ loadingTotal, setLoadingTotal ] = useState(false);
  const [ loadingPlaceForm, setLoadingPlaceForm ] = useState(false);
  const [ hideCart, setHideCart ] = useState(false);

  const { errorMsg } = useSelector(state => state.errors);
  const { genAddress } = useSelector(state => state.delivery);
  const { cartTotal } = useSelector(state => state.cart);
  const { currency, country } = useSelector(state => state.payments);
  const { userData } = useSelector(state => state.user);
  const { orderPlacedId } = useSelector(state => state.orderPlaced);
  const { stripeError } = useSelector(state => state.payments);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  
  const fetchTotalCartWithPromo = useCallback(
    (payload) => dispatch({ type: FETCH_ORDER_CART_TOTAL_PROMO_SAGA, payload }),
    [dispatch],
  );

  const deleteMenuItemOrder = useCallback(
    (payload) => dispatch({
      type: UPDATE_MENU_ITEMS_CART_SAGA,
      payload: {
        action: CRUD_ACTIONS.DELETE,
        payload: payload,
      }
    }),
    [dispatch],
  );

  const saveContact = (values) => {
    setContactData({...values});
    setIsContactSaved(true);
  };

  const handleSubmitCard = (paymentMethod, isPayedWithBtn=false) => {
    setPaymentMethod(paymentMethod.paymentMethod);
    setIsPayedWithBtn(isPayedWithBtn);
    if (isPayedWithBtn) {
      setIsContactSaved(true);
    }
    dispatch({ type: SET_STRIPE_ERROR_MSG, payload: null });
  };
  
  const clearCard = () => {
    setPaymentMethod(null);
  };

  const confirmDelete = ([id, index]) => {
    if (menuItemsCart && menuItemsCart.length > 1) {
      // can't delete last item
      deleteMenuItemOrder({id, index});
      // update total price
      dispatch({type: FETCH_ORDER_CART_TOTAL_SAGA});
    }
  };

  const applyPromoCode = () => {
    setLoadingTotal(true);
    fetchTotalCartWithPromo({ promoCode });
  };

  const updatePromoCode = (e) => {
    setPromoCode(e.target.value);
  };

  const onPlaceOrder = () => {
    if (!isContactSaved || !paymentMethod) {
      dispatch({ type: FETCH_DATA_ERROR_MSG, payload: t('app.checkout.warning-required-data') });
      if (!isContactSaved) {
        setContactError(t('app.checkout.warning-required-contact-data'));
      }
      if (!paymentMethod) {
        dispatch({ type: SET_STRIPE_ERROR_MSG, payload: t('app.checkout.warning-required-card-data') });
      }
    } else {
      dispatch({
        type: FETCH_ORDER_PLACE_SAGA,
        payload: {
          ...contactData,
          paymentMethod,
          stripe: stripeObj,
          promoCode,
          slug,
        },
      });
      setIsLoadingPage(true);
    }
  };
  
  useEffect(async () => {
    if (!stripeObj) {
      const stripe = await loadStripe(stripePk);
      setStripeObj(stripe);
    }
  }, []);

  useEffect(() => {
    setContactData({
      address1: `${genAddress.address1}, ${genAddress.address2}`,
      suite: genAddress.suite && genAddress.suite,
      contact_name: userData && `${userData.first_name} ${userData.last_name}`,
    });
  }, [genAddress]);

  useEffect(() => {
    setLoadingTotal(false);
    if (menuItemsCart && menuItemsCart.length) {
      // initial set cart summary information
      if (promoCode) {
        // apply with promocode for updating cart total
        applyPromoCode();
      } else {
        dispatch({type: FETCH_ORDER_CART_TOTAL_SAGA});
      }
    }
  }, [menuItemsCart]);

  useEffect(() => {
    setLoadingTotal(false);
    if (cartTotal.final_price && cartTotal.final_price !== paymentFinalPrice) {
      setPaymentFinalPrice(cartTotal.final_price);
      if (isPayedWithBtn) {
        // when final_price has been changed we should reset payment btn result
        setPaymentMethod(null);
      }
    }
  }, [cartTotal]);

  useEffect(() => {
    if (errorMsg) {
      setLoadingPlaceForm(false);
    }
  }, [orderPlacedId]);

  useEffect(() => {
    if (errorMsg) {
      setHideCart(false);
      setLoadingTotal(false);
      setLoadingPlaceForm(false);
    }
    setIsLoadingPage(false);
  }, [errorMsg, stripeError]);

  return (
    <>
      <Helmet>
        <title>Order Checkout</title>
      </Helmet>
      <main className="app__main">
      <Spin spinning={ isLoadingPage } indicator={ antIcon }>
        <div className="container">
          <div className="checkout__wrapper">
            <div className="checkout__wrap">
              <h1 className="mb-2"> { t('app.checkout.main-header') } </h1>
              <div className="checkout__info mb-3">
                <>
                  <p className="subtitle app__text-upper">{ t('app.checkout.delivery-header') }</p>
                  <div className="checkout__info_card">
                    { contactError &&
                      <span className="color_app__primary">{ contactError }</span>
                    }
                    { contactData &&
                    <Spin spinning={ loadingPlaceForm } indicator={ antIcon }>
                      { (!isContactSaved || isMobile ) ?
                        <FormContactCheckout
                          isMobile = { isMobile }
                          fetchGenAddress={ fetchGenAddress }
                          onFinish={ saveContact }
                          contactInitialState={ contactData }
                        />
                      :
                      <>
                        <Descriptions>
                          <Descriptions.Item
                            label={t('app.checkout.form-contact.address.label')}
                            span={3}
                          >
                            { contactData.address1 || '---' }
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={t('app.checkout.form-contact.address1.label')}
                            span={3}
                          >
                            { contactData.suite || '---' }
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={t('app.checkout.form-contact.contact_name.label')}
                            span={3}
                          >
                            { contactData.contact_name || '---' }
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={t('app.checkout.form-contact.note.label')}
                            span={3}
                          >
                            { contactData.note || '---' }
                          </Descriptions.Item>
                        </Descriptions>
                        <a
                          className="color_app__primary primary__link text-upper semi-bold"
                          onClick={() => setIsContactSaved(false)}
                        >
                          { t('app.checkout.change-btn') }
                        </a>
                      </> }
                    </Spin> }
                  </div>
                </>
              </div>
              <>
                <p className="subtitle app__text-upper">{ t('app.checkout.payment-header') } </p>
                <div className="checkout__payment">
                  <div className="checkout__payment_card">
                    { stripeError &&
                      <span className="color_app__primary">{ stripeError }</span>
                    }
                    { paymentMethod ?
                        <div className="inputted__card_info">
                          <div>
                            <span> { paymentMethod.card.brand } </span>
                            <span> **** { paymentMethod.card.last4 } </span>
                          </div>
                          <div>
                            <a
                              className="color_app__primary primary__link text-upper semi-bold"
                              onClick={() => clearCard()}
                            >
                              { t('app.checkout.change-btn') }
                            </a>
                          </div>
                        </div>
                      :
                        <Elements stripe={ stripeObj }>
                          <StripeRequestButtons
                            amount={cartTotal.final_price}
                            currency={ currency }
                            country={ country }
                            label={ LABEL_FOR_PAYS_BTNS }
                            handleSubmitCard={ (paymentMethod) => handleSubmitCard(paymentMethod, true) }
                            // use callback to save top level handler for isPayedWithBtn
                          />
                          <StripeCardForm
                            isMobile = { isMobile }
                            handleSubmitCard={ handleSubmitCard }
                          />
                        </Elements>
                    }
                  </div>
                </div>
              </>
              { !isMobile &&
              <div className="checkout__place_btn_wrapper mt-1">
                <Button
                  disabled={ !isContactSaved || !paymentMethod }
                  type="primary"
                  size="large"
                  htmlType="submit"
                  className="action__button action__button_md app__btn"
                  onClick={ () => onPlaceOrder() }
                >
                  { t('app.checkout.place-btn') }
                </Button>
              </div>
              }
            </div>
            <div className="checkout__wrap">
              { hideCart && <div className="disable__checkout_wrap"/> }
              <div className="checkout__cart_card">
                {!isMobile &&
                <div className="map_wrapper">
                  { (genAddress && genAddress.lat &&  genAddress.lat) &&
                    <MapWithAMarker
                      googleMapURL={googleMapURL}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `200px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                      defaultCenter={ {lat: genAddress.lat, lng: genAddress.lng} }
                      markerPosition={ {lat: genAddress.lat, lng: genAddress.lng} }
                    /> }
                </div> }
                <div className="checkout__cart_card__body">
                  <div className="header__cart">
                    <p className="subtitle">{ restaurantData.name }</p>
                    <Link
                      to={`${ROUTES.ORDER.link_path(slug)}/${ROUTES.ORDER.child.MENU.path}`}
                      className="subtitle"
                    >
                      { t('app.checkout.view-menu-link') }
                    </Link>
                  </div>
                  <div className="menu__items">
                    { menuItemsCart && menuItemsCart.map((el, ind) => {
                      return (
                        <div className="mb-1-75" key={`menu_card_item${ind}`}>
                          <CardMenuItem
                            menuItem = { el }
                            confirmDelete={ confirmDelete }
                            ind={ ind }
                          />
                        </div>
                      );
                    }) }
                  </div>
                  <div className="promo__form">
                    <div className="promo_input">
                      <Input onChange={ updatePromoCode } bordered={false} placeholder={'Enter promo code'} />
                    </div>
                    <div className="promo_action">
                      <a onClick={() => applyPromoCode()} className={ promoCode.length && 'active' }>APPLY</a>
                    </div>
                  </div>
                  { cartTotal &&
                    <Spin spinning={loadingTotal} indicator={antIcon}>
                      <CheckoutCartTotalTable cartTotal={cartTotal} />
                      <div className="cart_total__wrapper">
                        <div className="cart_total__wrap">
                          <span>{ t('app.checkout.final-price') }</span><span>{ priceFormatter(cartTotal.final_price) }</span>
                        </div>
                      </div>
                    </Spin>
                  }
                </div>
              </div>
            </div>
            { isMobile &&
            <div className="checkout__place_btn_wrapper mt-1">
              <Button
                disabled={ !isContactSaved || !paymentMethod }
                type="primary"
                size="large"
                htmlType="submit"
                className="action__button app__btn" block
                onClick={ () => onPlaceOrder() }
              >
                { t('app.checkout.place-btn') }
              </Button>
            </div> }
          </div>
        </div>
      </Spin>
    </main>
  </>
  );
};

export default TheOrderCheckout;
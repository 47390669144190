import { Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import { timeFormatter } from '../../utils';

const ModalNotServiceTime = (props) => {
  const { t } = useTranslation();
  const { isModalVisible,  setIsModalVisible, opensAt, closesAt } = props;

  const getTime = (date) => {
    if (!date) {
      return '';
    }
    return timeFormatter(new Date(date));
  };

  const closeThisModal = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      title={null}
      closable={true}
      width={650}
      onCancel={ closeThisModal }
      className="modal_not_service_time"
    >
      <div className="modal_not_service_time__body">
        <h3 className="text-1-375x semi-bold text-center mb-1">
          {  t('modal-not-service-time.header') }
        </h3>
        <div className="information__box  text-center mt-1 mb-1 text-1x">
          <p className="head__text">
            <span>{ t('modal-not-service-time.text-before-location') }</span>
            <span className="ml-0-25">{ t('modal-not-service-time.text-before-time') }</span>
            <span className="ml-0-25 color_app__primary">{`${getTime(opensAt)} - ${getTime(closesAt)}`}</span>
          </p>
        </div>
        <div className="button__wrapper">
          <Button
            onClick={ closeThisModal }
            className="action__button app__btn" type="primary" size={'large'}>
            {  t('modal-not-service-time.btn') }
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalNotServiceTime;

import { takeEvery, put, select } from "redux-saga/effects";
import { CRUD_ACTIONS } from '../../../const';

import {
  FETCH_DATA_404,
  UPDATE_MENU_ITEMS_CART_SAGA,
  SET_MENU_ITEMS_CART,
} from '../../actions/actionTypes';

function* workerOrderMenuItem(action) {
  const { payload } = action.payload;
  const menuItemsCart = yield select(state => state.order.menuItemsCart);

  if (action.payload.action === CRUD_ACTIONS.PUT) {
    yield put({type: SET_MENU_ITEMS_CART, payload: [...menuItemsCart, payload] });
  } else if (action.payload.action === CRUD_ACTIONS.DELETE) {
    const updatedMenuItems = menuItemsCart.filter((el, ind) => ind !== payload.index);
    yield put({type: SET_MENU_ITEMS_CART, payload: [...updatedMenuItems] });
  } else if (action.payload.action === CRUD_ACTIONS.CLEAR) {
    yield put({type: SET_MENU_ITEMS_CART, payload: [] });
  }
}

export default function* watchRestaurantMenuSaga() {
  yield takeEvery(UPDATE_MENU_ITEMS_CART_SAGA, workerOrderMenuItem);
}

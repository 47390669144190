import {
  SET_RESTAURANT_DATA,
  SET_RESTAURANT_MENU,
  SET_RESTAURANT_MENU_CATEGORIES,
} from '../../actions/actionTypes';

export const restaurantInitialState = {
  restaurantData: {},
  menu: [],
  menuCategories: [],
};

export default function setRestaurantData(state = restaurantInitialState, action) {
  switch (action.type) {
    case SET_RESTAURANT_DATA:
      return {
        ...state,
        restaurantData: action.payload,
      };
    case SET_RESTAURANT_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case SET_RESTAURANT_MENU_CATEGORIES:
      return {
        ...state,
        menuCategories: action.payload,
      };
    default:
      return state;
  }
}
import { takeLatest, put, select, call } from 'redux-saga/effects';

import OrderRepository from '../../repositories/order';
import BaseRepositoryError from '../../repositories/base.errors';
import { addressForOrderApi } from '../../selectors/delivery';
import { userDataForOrderApi } from '../../selectors/user';
import { menuItemsForOrderApi } from '../../selectors/order';

import {
  FETCH_DATA_404,
  FETCH_ORDER_CART_TOTAL_SAGA,
  SET_DATA_ERROR_MSG,
  SET_ORDER_CART_TOTAL,
} from '../../actions/actionTypes';


function* workerOrderCartTotal(action) {
  // user data
  // delivery data
  const addressData = yield select(addressForOrderApi);
  const userData = yield select(userDataForOrderApi);
  const menuItemsData = yield select(menuItemsForOrderApi);
  const payloadsData = {
    promo_code: action.payload && action.payload.promoCode,
  };
  try {
    const response = yield call(OrderRepository.cart, {addressData, userData, menuItemsData, payloadsData});
    yield put ({ type: SET_ORDER_CART_TOTAL, payload: response });
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
    }
  }
}

export default function* watchOrderCartTotal() {
  yield takeLatest(FETCH_ORDER_CART_TOTAL_SAGA, workerOrderCartTotal);
}

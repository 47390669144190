import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import DesktopHeader from './DesktopHeader';
import MobileHeader from './MobileHeader';
import '../Headers.less';

import AuthService from '../../../services/auth';
import ROUTES from '../../../routes';
import { FETCH_LOGOUT_SAGA } from '../../../redux/actions/actionTypes';
import { Avatar, Dropdown, Menu } from 'antd';


const TheAppHeader = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { genAddress } = useSelector(state => state.delivery);
  const { restaurantSlug } = useSelector(state => state.root);
  const userData = useSelector(state => state.user.userData);

  const fetchLogout = useCallback(
    () => dispatch({ type: FETCH_LOGOUT_SAGA }),
    [dispatch],
  );

  const passProps = {
    ...props,
    isAuthenticated: AuthService.isAuthenticated(),
    fetchLogout: fetchLogout,
    userData,
    restaurantSlug,
    signInPath: `${ROUTES.AUTH.path}/${ROUTES.AUTH.child.SIGN_IN.path}`,
    signUpPath: `${ROUTES.AUTH.path}/${ROUTES.AUTH.child.SIGN_UP.path}`,
    authSuccessRouter: pathname,
    genAddress
  };
  
  return (
    <>
      { props.isMobile ? <MobileHeader {...passProps}/> : <DesktopHeader {...passProps}/> }
    </>
  );
};

export default TheAppHeader;

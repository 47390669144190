import { useState, useEffect } from 'react';
import { Layout, Button, Spin } from 'antd';
import { useHistory } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';

import ROUTES from '../../../routes';
import BaseLandingHeader from '../../../components/Headers/BaseLandingHeader';
import PlacesAutocomplete from '../../../components/Form/PlacesAutocomplete';
import LandingFooter from '../../../components/Footers/LandingFooter';
import defaultBgImg from '../../../assets/restaurantLanging/jumbotron/default_bg.png';

const { Footer, Content } = Layout;

const BaseLanding = (props) => {
  const routerHistory = useHistory();
  const { restaurantData, slug, userData, fetchLogout, isAuthenticated } = props;
  const [bgImgSrc, setBgImgSrc] = useState('');

  const antIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

  useEffect(() => {
    if (restaurantData.name) {
      const imageLoader = new Image();
      // load image bg either set default
      imageLoader.src = restaurantData.background_img || defaultBgImg;
      imageLoader.onload = () => {
        setBgImgSrc(imageLoader.src);
      };
    }
    }, [restaurantData]);

  const bgStyle = {
    background: `linear-gradient(rgba(20, 20, 20, 0.4), rgba(20, 20, 20, 0.4)), url(${bgImgSrc})`,
  };

  const placesHandler = (payload) => {
    // fetchGenAddress with google placesHandler list
    props.fetchGenAddress(payload);
  };

  const pushSuccess = () => {
    routerHistory.push(props.successRouter);
  };

  return (
    <Layout className="base_landing__layout">
      { true &&
        <>
          <BaseLandingHeader
            userData={userData}
            isAuthenticated={isAuthenticated}
            fetchLogout={fetchLogout}
            signInPath={`${ROUTES.AUTH.path}/${ROUTES.AUTH.child.SIGN_IN.path}`}
            signUpPath={`${ROUTES.AUTH.path}/${ROUTES.AUTH.child.SIGN_UP.path}`}
            authSuccessRouter={`${ROUTES.RESTAURANT_LANDING.link_path(slug)}`}
          ></BaseLandingHeader>
          <Content>
            <div className="base_landing__jubmbotron" style={bgStyle}>
              <h1>{props.restaurantData.name}</h1>
              <PlacesAutocomplete
                 className="landing__autocomplete"
                 onPlaceGet={placesHandler}
               />
                <Button
                  disabled={props.isGenAddressCovered === undefined}
                  type="primary" size="large" className="action__button mt-3 search"
                  onClick={(e) => pushSuccess()}
                >
                  Search
                </Button>
            </div>
          </Content>
          <LandingFooter></LandingFooter>
        </>
      }
    </Layout>
  );
};

export default BaseLanding;

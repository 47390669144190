import { put, takeEvery, select, call } from "redux-saga/effects";
import BaseRepositoryError from '../../repositories/base.errors';
import RestaurantRepository from '../../repositories/restaurant';

import {
  FETCH_DATA_404,
  FETCH_RESTAURANT_MENU_DATA_SAGA,
  SET_RESTAURANT_MENU,
  SET_RESTAURANT_MENU_CATEGORIES,
} from '../../actions/actionTypes';

function* workerFetchRestaurantMenuData(action) {
  try {
    const { id } = yield select(state => state.restaurant.restaurantData);
    if (id) {
      const menu = yield call(RestaurantRepository.getRestaurantMenu, {restaurantId: id});
      yield put({type: SET_RESTAURANT_MENU, payload: menu.items ? menu.items : []});
      yield put({type: SET_RESTAURANT_MENU_CATEGORIES, payload: menu.categories ? menu.categories : []});
    }
  } catch (e) {
    if (e instanceof BaseRepositoryError) {
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* watchRestaurantMenuSaga() {
  yield takeEvery(FETCH_RESTAURANT_MENU_DATA_SAGA, workerFetchRestaurantMenuData);
}

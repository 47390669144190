import { all, fork } from "redux-saga/effects";

import watchRestaurantSaga from './watchRestaurantSaga';
import watchRestaurantMenuSaga from './watchRestaurantMenuSaga';

function* rootRestaurant() {
  yield all([
    fork(watchRestaurantSaga),
    fork(watchRestaurantMenuSaga),
  ]);
}
export default rootRestaurant;
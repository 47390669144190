const FileInput = ({ alt, capture, icon, id, onChange, src, title }) => {
  return (
    <div
      className="content-container image-container"
      onClick={() => document.querySelector(`#${id}`).click()}
    >
      {src ? <img alt={alt} src={src} /> : icon}
      <input
        accept="image/*"
        capture={capture}
        id={id}
        onChange={onChange}
        type="file"
      />
      <div className="content-container label text centered-text grey mini">
        {title}
      </div>
    </div>
  );
};

export default FileInput;

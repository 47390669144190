import firebase from "firebase/app";
import "firebase/storage";

import {firebaseConfig} from '../../configs';

const FirebaseService = {
  init() {
    firebase.initializeApp(firebaseConfig);
  },

  upload(url, data) {
    const ref = firebase.storage().refFromURL(url);
    const uploadTask = ref.put(data);
    return uploadTask;
  },
};

export default FirebaseService;

import React, { useEffect } from 'react';
import { Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import ROUTES from './index';
import AuthService from '../services/auth';

const PrivateRoute = ({component: Component, ...rest}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const userData = useSelector(state => state.user.userData);

  const isAuthenticated = () => {
    return AuthService.isAuthenticated();
  };

  useEffect(() => {
    if (userData.id) {
      const authSuccessRouter = pathname;
      if (!userData.phone_verified) {
        history.push({pathname: `${ ROUTES.AUTH.path }/${ ROUTES.AUTH.child.ADD_PHONE.path }`, state: {authSuccessRouter}});
      }
    }
  }, [userData]);

  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /sign in page
    <Route {...rest} render={props => (
      isAuthenticated() ?
        <Component {...props} /> :
        <Redirect to={{ pathname: `${ROUTES.AUTH.path}/${ROUTES.AUTH.child.SIGN_IN.path}`, state: {authSuccessRouter: pathname}}}/>
    )} />
  );
};

export default PrivateRoute;
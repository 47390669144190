class BaseRepositoryError extends Error {
  constructor(errorCode, response) {
    super();
    if (errorCode !== 500) {
      this.message = this.parseErrorData(response.data);
    } else {
      this.message = 'Server Error. Please, contact support.';
    }
    this.name = this.constructor.name;
    this.errorCode = errorCode;
  }

  parseErrorData(data) {
    let message = '';
    if (data && data.message) {
      return data['message'];
    } else {
      Object.keys(data).forEach((key, index) => {
        if (data[key] instanceof Array) {
          message = index !== 0 ? `${ message }, ${ key }` : `${ key }`;
          data[key].forEach((value) => {
            message = `${ message } ${ value }`;
          });
        } else {
          message = `${ key } ${ data[key] }`;
        }
      });
    }
    return message;
  };
}

export default BaseRepositoryError;
import { put, takeLatest, call } from 'redux-saga/effects';

import BaseRepositoryError from '../../repositories/base.errors';
import RestaurantRepository from '../../repositories/restaurant';

import {
  SET_DATA_ERROR_MSG,
  FETCH_DATA_404,
  FETCH_GEN_ADDRESS_SAGA,
  FETCH_SERVICE_INFO_SAGA,
  SET_GEN_ADDRESS_SUCCESS,
  SET_GEN_ADDRESS_IS_COVERAGE,
} from '../../actions/actionTypes';

function* workerFetchGenAddress(action) {
  try {
    const { in_service_region } = yield call(RestaurantRepository.getRestaurantData, action.payload );
    yield put({type: SET_GEN_ADDRESS_IS_COVERAGE, payload: { isGenAddressCovered: in_service_region }});
    yield put({ type: SET_GEN_ADDRESS_SUCCESS, payload: action.payload });
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put({ type: SET_DATA_ERROR_MSG, payload: e.message });
    }
  }
}

export default function* watchRestaurantSaga() {
  yield takeLatest(FETCH_GEN_ADDRESS_SAGA, workerFetchGenAddress);
}
import { useState } from 'react';
import { priceFormatter } from '../../../../utils';
import { useParams, Link } from 'react-router-dom';

import ROUTES from '../../../../routes';
import CartTable from './CartTable';
import { useTranslation } from 'react-i18next';

const DesktopCart = (props) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { refOutsideClick, isShow, menuItemsCart, deleteMenuItemOrder, handleShowCart } = props;
  const [openedSublist, setOpenedSublist] = useState({});

  const updateOpenedSublist = (key) => {
    if (!Object.keys(openedSublist).indexOf(key)) {
      setOpenedSublist({});
    } else {
      setOpenedSublist({[key]: true});
    }
  };

  const confirmDelete = ([id, index]) => {
    deleteMenuItemOrder({id, index});
  };

  return (
    <>
      {isShow ?
        <>
          <div className="cart__popover" ref={refOutsideClick}>
            <h5 className="text-1x">
              { t('app.cart.title-expanded') }
            </h5>
            <div className="cart_overflow__scroll">
              <CartTable
                menuItemsCart={menuItemsCart}
                openedSublist={openedSublist}
                updateOpenedSublist={updateOpenedSublist}
                confirmDelete={confirmDelete}
              />
            </div>
            <div className="cart_summary mt-1-25">
              <span className="semi-bold">
                { t('app.cart.subtotal') }
              </span>
              <span className="color_app__primary semi-bold">
                { priceFormatter(menuItemsCart.reduce((accPrice, item) => (item.totalPrice + accPrice), 0)) }
              </span>
            </div>
            <div className="cart__checkout__box">
              <Link
                to={ `${ROUTES.ORDER.link_path(slug)}/${ROUTES.ORDER.child.CHECKOUT.path}` }
                onClick={ () => handleShowCart(false) }
                disabled={ !menuItemsCart.length }
                type="primary" size="large"
                className="action__button app__btn mt-2-25" block
              >
                { t('app.cart.btn-checkout') }
              </Link>
            </div>
          </div>
        </>
      : ''}
    </>
  );
};

export default DesktopCart;
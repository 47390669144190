import { useTranslation } from 'react-i18next';

const SignIn = () => {
  const { t } = useTranslation();
  
  return(
    <div>
      <div className="mt-1 mb-1 text-center">
        <h1 className="color_app__primary">
          { t('auth.reset-password-sent') }
        </h1>
        { t('auth.reset-password-sent-text') }
      </div>
    </div>
  );
};

export default SignIn;
import { useTranslation } from 'react-i18next';
import { Row } from 'antd';
import PrivateRoute from '../../routes/PrivateRoute';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation, Link, useRouteMatch } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import TheAppHeader from '../../components/Headers/TheAppHeader';
import ProfileUpdate from './ProfileUpdate';

import ROUTES from '../../routes';

const Profile = () => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: '(max-width: 758px)' });
  const { path } = useRouteMatch();
  const { restaurantSlug } = useSelector(state => state.root);
  
  return (
    <div className="full">
      <TheAppHeader isMobile={isMobile} restaurantSlug={restaurantSlug} />
      <div className="auth__wrapper">
        <div className="profile_card__wrapper">
          <Switch>
            <Route path={`${path}/${ROUTES.PROFILE.child.UPDATE.path}`}>
              <PrivateRoute component={ProfileUpdate}/>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Profile;

import {SET_UPDATE_RESTRICTED_ORDER_DATA} from '../../actions/actionTypes';

export const rcInitialState = {orderUpdate: {}};

export default function restrictedData(state = rcInitialState, action) {
  switch (action.type) {
    case SET_UPDATE_RESTRICTED_ORDER_DATA:
      return {...state, orderUpdate: action.payload};
    default:
      return state;
  }
}

import { Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import logoImg from '../../assets/white_logo.png';
import LogoBox from './LogoBox.jsx';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const BaseLandingHeader = (props) => {
  const { signInPath, signUpPath, authSuccessRouter, fetchLogout, isAuthenticated } = props;
  const { t } = useTranslation();
  
  const menu = (
    <Menu>
      <Menu.Item>
        <a onClick={ () => fetchLogout() }>Logout</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className="base_landing__header">
      <LogoBox logoImg={logoImg} />
      { isAuthenticated ?
        <div className='auth__user_button'>
          <Dropdown overlay={menu} trigger={['click']}>
            <Avatar style={{ backgroundColor: '#f56a00' }} icon={<UserOutlined/>}/>
          </Dropdown>
        </div> :
        <div className='auth__buttons'>
          <Link to={ {pathname: signInPath, state: {authSuccessRouter}} }>
            { t('auth.log_in') }
          </Link>
          <Link
            to={ {pathname: signUpPath, state: {authSuccessRouter}} }
            type="primary" size="large" className="action__button sign-up"
          >
            { t('auth.sign_up') }
          </Link>
        </div>
      }
    </header>
  );
};

export default BaseLandingHeader;

import AuthButtons from './AuthButtons';

const LogoBox = (props) => {
  const { logoImg } = props;
  return (
    <div className="header__logo">
      <img src={logoImg} alt="Coco"/>
    </div>
  );
};

export default LogoBox;

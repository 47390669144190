import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { priceFormatter } from '../../../../utils';
import CardMenuItem from '../../../../components/Cards/CardMenuItem';
import { Link, useHistory, useParams } from 'react-router-dom';
import ROUTES from '../../../../routes';


const MobileCart = (props) => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const { handleShowCart, isShow, menuItemsCart, deleteMenuItemOrder } = props;
  const [openedSublist, setOpenedSublist] = useState({});
  const history = useHistory();

  const updateOpenedSublist = (key) => {
    if (!Object.keys(openedSublist).indexOf(key)) {
      setOpenedSublist({});
    } else {
      setOpenedSublist({[key]: true});
    }
  };
  
  const goToCheckout = () => {
    history.push({pathname: `${ROUTES.ORDER.link_path(slug)}/${ROUTES.ORDER.child.CHECKOUT.path}`});
    handleShowCart(false);
  };

  const confirmDelete = ([id, index]) => {
    deleteMenuItemOrder({id, index});
  };

  return (
    <>
      <Modal
        style={ { height: `${window.innerHeight}px` }}
        visible={ isShow }
        width={'auto'}
        closeIcon={ <span className="coco__icon close lg"/> }
        onCancel={ () => handleShowCart(false) }
        className="modal-menu mobile__full_screen_modal mobile__modal_cart"
        title={ <h3 className="text-2x bold" >{ t('app.cart.title-mobile') }</h3> }
        footer={ null }
      >
        { menuItemsCart && menuItemsCart.map((el, ind) => {
          return (
            <div className="mb-1-75" key={`menu_card_item${ind}`}>
              <CardMenuItem
                menuItem = { el }
                confirmDelete={ confirmDelete }
                ind={ ind }
              />
            </div>
          );
        }) }
        <div className="cart_summary">
          <span className="semi-bold">
            { t('app.cart.subtotal') }
          </span>
          <span className="color_app__primary semi-bold">
            { priceFormatter(menuItemsCart.reduce((accPrice, item) => (item.totalPrice + accPrice), 0)) }
          </span>
        </div>
        <div className='mobile__absolute_bottom__wrapper'>
          <Button
            to={ `${ROUTES.ORDER.link_path(slug)}/${ROUTES.ORDER.child.CHECKOUT.path}` }
            onClick={ () => goToCheckout() }
            type="primary" size="large"
            className="action__button app__btn" block
          >
            <span>
              { t('app.cart.btn-checkout') }
            </span>
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default MobileCart;
import {
  SET_USER_DATA,
} from '../../actions/actionTypes';

export const userInitialState = {
  userData: {},
};

export default function setUserData(state = userInitialState, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload,
      };
    default:
      return state;
  }
}
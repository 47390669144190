import { takeEvery, put, select, call } from "redux-saga/effects";
import { CRUD_ACTIONS } from '../../../const';

import {
  FETCH_DATA_404,
  SET_DATA_ERROR_MSG,
  FETCH_ORDER_DATA_SAGA,
  SET_ORDER_PLACED_DATA,
} from '../../actions/actionTypes';
import OrderRepository from '../../repositories/order';
import BaseRepositoryError from '../../repositories/base.errors';

function* workerOrderData(action) {
  const orderId = action.payload;
  try {
    const response = yield call(OrderRepository.getOrderData, orderId);
    yield put ({ type: SET_ORDER_PLACED_DATA, payload: {...response, orderId} });
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* watchOrderData() {
  yield takeEvery(FETCH_ORDER_DATA_SAGA, workerOrderData);
}

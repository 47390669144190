import { Button, Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import PlacesAutocomplete from '../../../components/Form/PlacesAutocomplete';

const FormContactCheckout = (props) => {
  const { t } = useTranslation();
  const { onFinish, contactInitialState, fetchGenAddress, isMobile } = props;

  return (
    <Form
      className="checkout__form"
      layout={ 'vertical' }
      onFinish={ onFinish }
      onValuesChange={ (changedValues, allValues) => onFinish(allValues)} // for mobile
      initialValues={ contactInitialState }
    >
      <Form.Item
        label={ t('app.checkout.form-contact.address.label') }
        name="address1"
      >
        <PlacesAutocomplete
          isNotBordered={true}
          onPlaceGet={fetchGenAddress}
          className='ant-form-item-control'
          initialValues={contactInitialState.address1}
        />
      </Form.Item>
      <Form.Item
        label={ null }
        name="suite"
      >
        <Input bordered={ false } placeholder={ t('app.checkout.form-contact.address1.placeholder') }/>
      </Form.Item>
      <Form.Item
        label={ t('app.checkout.form-contact.contact_name.label') }
        name="contact_name"
      >
        <Input bordered={ false } placeholder={ t('app.checkout.form-contact.contact_name.placeholder') }/>
      </Form.Item>
      <Form.Item
        className="no__border"
        label={ null }
        name="note"
      >
        <Input.TextArea placeholder={ t('app.checkout.form-contact.note.placeholder') }/>
      </Form.Item>
      <Form.Item
        label={ null }
        className={'btn__wrapper'}
      >
        { !isMobile &&
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            className="action__button_sm app__btn"
          >
            { t('app.checkout.save-btn') }
          </Button>
        }
      </Form.Item>
    </Form>
  );
};

export default FormContactCheckout;
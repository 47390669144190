import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import {
  FETCH_RESET_PASSWORD_SAGA,
} from '../../redux/actions/actionTypes';

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formLayout, setFormLayout] = useState('vertical');
  const [loading, setLoading] = useState(false);
  const { errorMsg } = useSelector(state => state.errors);
  
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  useEffect(() => {
    if (errorMsg) {
      setLoading(false);
    }
  }, [errorMsg]);
  
  const fetchResetPassword = useCallback(
    (payload) => dispatch({ type: FETCH_RESET_PASSWORD_SAGA,  payload }),
    [dispatch],
  );

  const onFinish = (values) => {
    fetchResetPassword(values);
    setLoading(true);
  };
  
  return(
    <div>
      <Spin spinning={loading} indicator={antIcon}>
        <div className="mt-1 mb-1 text-center">
          <h1 className="color_app__primary">{ t('auth.reset-password') }</h1>
          { t('auth.reset-password-text') }
        </div>
        <div className="auth_card__wrap">
          <Form
            className="auth__form"
            layout={formLayout}
            size={'large'}
            initialValues={{ layout: formLayout }}
            onFinish={onFinish}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={ [
                { required: true, message: t('form.warning.email-required') },
                { type: 'email', message: t('form.warning.email-type') },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label=" " colon={false}>
              <Button
                type="primary"
                size="large"
                htmlType="submit"
                className="auth_btn app__btn text-upper" block
              >
                { t('auth.reset-password') }
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

export default SignIn;
import React, {useState, useEffect} from 'react';
import { Button } from 'antd';
import { CreditCardOutlined } from '@ant-design/icons';
import { CardElement, useStripe } from '@stripe/react-stripe-js';

const StripeRequestButtons = (props) => {
  const { handleSubmitCard, amount, currency, country, label } = props;
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [isApplePay, setIsApplePay] = useState(false); //
  
  useEffect(() => {
    if (stripe && amount) {
      const pr = stripe.paymentRequest({
        country,
        currency,
        total: {
          amount,
          label,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });
      
      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        // for now result just has applePay boolean even if google
        if (result) {
          setPaymentRequest(pr);
          if (result.applePay !== undefined) {
            setIsApplePay(result.applePay);
          }
        }
      });
    }
  }, [stripe, amount]);
  
  
  if (paymentRequest) {
    paymentRequest.on('paymentmethod', async (ev) => {
      if (ev) {
        if (ev.paymentMethod) {
          ev.complete('success');
          await handleSubmitCard({ paymentMethod: ev.paymentMethod });
        } else {
          ev.complete('fail');
        }
      }
    });
  }

  return (
    <>
    { paymentRequest &&
      <div className="mb-1">
        <Button
          disabled={ !stripe && !amount }
          type="primary"
          size="large"
          htmlType="submit"
          className="action__button_md app__btn" block
          onClick={ () => paymentRequest.show() }
        >
          { isApplePay ?
            <span className='coco__icon apple md action_btn__icon mr-0-25'/>
            :
            <CreditCardOutlined/>
          }
          Pay
        </Button>
      </div>
    }
    </>
  );
};

export default StripeRequestButtons;

import {Button} from 'antd';

import cocoRobot from '../../../assets/coco-robot.png';

const VerificationPage = ({
  buttonClasses = [],
  buttonOnClick,
  buttonText,
  canContinue = false,
  hasRobot = false,
  linkText,
  linkUrl,
  onBack,
  renderContent,
  subtitle,
  text,
  textClasses = [],
  title
}) => {
  const finalButtonClass = ['button', ...buttonClasses].join(' ');
  const finalTextClass = ['centered-text', 'paragraph', 'text', ...textClasses].join(' ');

  return (
    <div className="center-col verification__wrapper">
      <div className="center-col f-2 full">
        <h1 className="centered-text text title">
          {title}
        </h1>
        {!!hasRobot ?
          <>
            <div className="coco-robot-container">
              <img alt="Coco Robot" src={cocoRobot} />
            </div>
            <div className="centered-text subtitle text">
              {subtitle}
            </div>
          </> :
          <>
            <div className="centered-text mini subtitle text">
              {subtitle}
            </div>
          </>
        }
        {!!renderContent &&
          <div className="center-col full j-end">
            {renderContent()}
          </div>
        }
        {!!text &&
          <div className={finalTextClass}>
            {text}
          </div>
        }
        {!!linkText &&
          <div>
            <a href={linkUrl}  target="_blank" className="centered-text link mini text">
              {linkText}
            </a>
          </div>
        }
      </div>
      <div className="end-button-container">
        <Button className={finalButtonClass} disabled={!canContinue} onClick={buttonOnClick}>
          {buttonText}
        </Button>
        {!!onBack && <span onClick={onBack}>Back</span>}
      </div>
    </div>
  );
};

export default VerificationPage;

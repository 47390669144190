import { all, fork } from 'redux-saga/effects';
import WatchSignIn from './WatchSignIn';
import WatchSignInOauth from './WatchSignInOauth';
import WatchSignUp from './WatchSignUp';
import WatchLogout from './WatchLogout';
import WatchResetPassword from './WatchResetPassword';

function* rootAuth() {
  yield all([
    fork(WatchSignIn),
    fork(WatchSignInOauth),
    fork(WatchSignUp),
    fork(WatchLogout),
    fork(WatchResetPassword),
  ]);
}

export default rootAuth;
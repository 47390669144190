const SUCCESS_STATUS = 'succeeded';

class StripeServiceError extends Error {
  constructor(error) {
    super();
    this.message = error.message;
  }
}

const StripeService = {
  async completePaymentIntent(stripe, clientSecret, paymentMethod) {
    const res = await stripe.confirmPaymentIntent(clientSecret, {...paymentMethod});
    if (res && res.error) {
      throw new StripeServiceError(res.error);
    } else if (res && res.paymentIntent) {
      return res.paymentIntent.status === SUCCESS_STATUS;
    } else {
      return false;
    }
  },
};

export { StripeServiceError };

export default StripeService;
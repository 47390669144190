import { Button } from 'antd';

import { truncateText, priceFormatter } from '../../../utils';

const MenuCard = (props) => {
  const { name, description, price, imageUrl, onAdd } = props;

  // const bgStyle = {
  //   background: `url(${imageUrl})`,
  //   backgroundSize: 'cover',
  //   backgroundPosition: 'center',
  // };

  return (
    <div className="mobile_menu__card mobile_menu__card__wrapper" onClick={ onAdd }>
      <div className="menu__card_wrap card_info">
        <h3 className="mb-1 semi-bold">
          { truncateText(name, 50) }
        </h3>
        <p className="card_info__decs mb-1 app_medium_color">
          { truncateText(description, 60) }
        </p>
        <p className=" mb-1 semi-bold color_app__primary">
          { priceFormatter(price) }
        </p>
      </div>
      <div className="menu__card_wrap card_image">
        <div className="image_wrapper">
          <Button onClick={ onAdd } className="add_btn" type="primary" shape="circle" size={'small'}>
            <span className="coco__icon plus md color_divider_app__icon action_btn__icon"/>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MenuCard;
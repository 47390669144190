import PropTypes from 'prop-types';
import usePlacesAutocomplete, { getGeocode, getLatLng, getZipCode, } from 'use-places-autocomplete';
import { Input, AutoComplete } from 'antd';
import './index.less';
import { ParseAutoplacedAddressLine } from '../../../utils/';

const PlacesAutocomplete = (props) => {
  const { className, initialValues, isNotBordered, isAllowClear } = props;
  const {
    ready, value, suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({requestOptions: { }, debounce: 800});

  const handleInput = (value) => {
    // Update the keyword of the input element
    setValue(value);
  };

  const handleSelect = async (addressLine) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(addressLine, false);
    clearSuggestions();
    try {
      const results = await getGeocode({address: addressLine});
      const { lat, lng } = await getLatLng(results[0]);
      const zipCode = await getZipCode(results[0]);
      const formattedAddress =  results[0].formatted_address;
      const { address1, address2 } = ParseAutoplacedAddressLine(results[0].formatted_address);
      props.onPlaceGet({ lat, lng, address1, address2, addressLine: formattedAddress, zipCode });
      setValue('');
    } catch (e) {
      console.debug("😱 Error: ", e);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        description,
        place_id,
        structured_formatting: { main_text, secondary_text },
        suggestions,
      } = suggestion;
      return (
        {
          value: description,
          label: <div key={place_id}><strong>{main_text}</strong> <small>{secondary_text}</small></div>,
        }
      );
    });

  return (
    <div className={className || 'landing__autocomplete'}>
      { ready ?
        <AutoComplete
          disabled={ !ready }
          options={ renderSuggestions() }
          onSelect={ handleSelect }
          onSearch={ handleInput }
          defaultValue={ initialValues || null }
        >
          <Input name="address" allowClear={ isAllowClear } bordered={ !isNotBordered } size="large"
                 placeholder="Enter your address"/>
        </AutoComplete> : null
      }
    </div>
  );
};

PlacesAutocomplete.defaultProps = {
  isAllowClear: false,
  isNotBordered: false,
};

export default PlacesAutocomplete;
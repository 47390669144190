import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";

import { Helmet } from "react-helmet";

import { LandingContext } from './contex';
import BaseLanding from './components/BaseLanding';
import AuthService from '../../services/auth';
import ROUTES from '../../routes';
import { FETCH_RESTAURANT_DATA_SAGA, FETCH_GEN_ADDRESS_SAGA, FETCH_LOGOUT_SAGA } from '../../redux/actions/actionTypes';


const RestaurantLanding = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();

  const restaurantData = useSelector(state => state.restaurant.restaurantData);
  const isGenAddressCovered = useSelector(state => state.delivery.isGenAddressCovered);
  const userData = useSelector(state => state.user.userData);

  const fetchGenAddress = useCallback(
    (payload) => dispatch({ type: FETCH_GEN_ADDRESS_SAGA, payload: {...payload, slug}  }),
    [dispatch],
  );

  const fetchLogout = useCallback(
    () => dispatch({ type: FETCH_LOGOUT_SAGA }),
    [dispatch],
  );

  useEffect(() => {
    dispatch({ type: FETCH_RESTAURANT_DATA_SAGA, payload: { slug } });
  }, []);

  useEffect(() => {
  }, [restaurantData]);

  return (
    <LandingContext.Provider
      value={{}}
    >
    <div className="full">
      { restaurantData &&
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{restaurantData.name}</title>
          </Helmet>
          <BaseLanding
            fetchLogout={fetchLogout}
            userData={userData}
            isAuthenticated={AuthService.isAuthenticated()}
            successRouter={`${ROUTES.ORDER.link_path(slug)}/${ROUTES.ORDER.child.MENU.path}`}
            slug={slug}
            fetchGenAddress={fetchGenAddress}
            restaurantData={restaurantData}
            isGenAddressCovered={isGenAddressCovered}
          />
        </>
      }
    </div>
    </LandingContext.Provider>
  );
};

export default RestaurantLanding;

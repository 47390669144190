import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { Button, Form, Spin } from 'antd';
import './stripe.less';
import { useTranslation } from 'react-i18next';

const StripeCardForm = (props) => {
  const { t } = useTranslation();
  const { handleSubmitCard, isMobile } = props;
  const [error, setError] = useState(null);
  const [disabled, setDisabled] = useState(true);
  
  const elements = useElements();
  const stripe = useStripe();

  const cardStyle = {
    style: {
      base: {
        fontFamily: 'Inter, sans-serif',
        fontSize: "14px",
        borderBottom: "1px solid",
        "::placeholder": {
          color: "grey"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    }
  };
  const handleChange = async (event) => {
    // Listen for changes in the CardElement
    // and display any errors as the customer types their card details
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };
  
  const onFinish = async ev => {
    try {
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
      await handleSubmitCard(paymentMethod);
    } catch (e) {
      setError(e.message);
    }
  };

  return (
      <Form
        className="checkout__form pay"
        layout={ 'vertical' }
        onFinish={ onFinish }
      >
        <Form.Item
          label={null}
          name="card"
          validateStatus={error && 'error'}
          help={error}
        >
          <CardElement id="card-element" options={ cardStyle } onChange={handleChange} />
        </Form.Item>
        <Form.Item
          label={ null }
          className={'btn__wrapper'}
        >
          { isMobile ?
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="action__button app__btn text-upper" block
            >
              { t('app.checkout.save-btn') }
            </Button>
            :
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="action__button_sm app__btn"
            >
              { t('app.checkout.save-btn') }
            </Button>
          }
        </Form.Item>
      </Form>
  );
};

export default StripeCardForm;
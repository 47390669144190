import { priceFormatter } from '../../../utils';
import { useTranslation } from 'react-i18next';

const CheckoutCartTotalTable = (props) => {
  const { t } = useTranslation();
  const { cartTotal } = props;
  return (
    <>
      <div className="cart_subtotal__wrapper">
        <div className="cart_subtotal__wrap">
          <span>{ t('app.checkout.subtotal') }</span><span>{ priceFormatter(cartTotal.subtotal_price) }</span>
        </div>
        <div className="cart_subtotal__wrap">
          <span>{ t('app.checkout.sales-tax') }</span><span>{ priceFormatter(cartTotal.sales_tax) }</span>
        </div>
        <div className="cart_subtotal__wrap">
          <span>{ t('app.checkout.delivery-fee') }</span><span>{ priceFormatter(cartTotal.delivery_fee) }</span>
        </div>
        {cartTotal.discount ? (
        <div className="cart_subtotal__wrap cart_subtotal_discount">
          <span>{ t('app.checkout.discount') } </span><span>-{ priceFormatter(cartTotal.discount) }</span>
        </div>
        ): <></>}
      </div>
    </>
  );
};

export default CheckoutCartTotalTable;
import { all, fork } from 'redux-saga/effects';
import watchGenAddress from './watchGenAddress';
import watchFetchServiceInfo from './watchFetchServiceInfo';

function* rootDelivery() {
  yield all([
    fork(watchGenAddress),
    fork(watchFetchServiceInfo),
  ]);
}

export default rootDelivery;
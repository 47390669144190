
export const apiHosts = {
  baseHost: process.env.REACT_APP_BASE_API_URL || '/',
};

export const OauthProviders = {
  google: {
    key: 'google',
    uri: process.env.REACT_APP_OAUTH_GOOGLE_CLIENT_URL || "365598872681-i7nqcjbpijlj93ibab2c9te3k3fk0q24.apps.googleusercontent.com",
  }
};

export const stripePk =  process.env.REACT_APP_STRIPE_PK || 'pk_test_8mZG4iPVcPAJCaphKteduKvO00ESmYVFLO';
export const googleMapURL =  process.env.REACT_APP_GOOGLE_MAP_URL || 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBtoGQkPEVjcqN_bYbvvcs04llCpcYwvdQ&mapId=1b1f0f1beafdbfd9&v=3.exp&libraries=geometry,drawing,places';
export const orderApiKey =  process.env.REACT_APP_ORDER_API_KEY || 'AIzaSyCmj8SZ5F4ost5INvJeIhkpA_hZ4jc4ewQ';

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DB_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_DEFAULT_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID
};

import { all, fork } from 'redux-saga/effects';
import rootAuth from './auth';
import rootRestaurant from './restauraunt';
import rootDelivery from './delivery';
import watchErrorSaga from './errors';
import rootOrder from './order';
import rootRestricted from './restricted';
import rootUser from './user';

export default function* rootSaga() {
  yield all([
    fork(watchErrorSaga),
    fork(rootRestaurant),
    fork(rootDelivery),
    fork(rootOrder),
    fork(rootAuth),
    fork(rootUser),
    fork(rootRestricted),
  ]);
}

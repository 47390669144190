import {
  SET_GEN_ADDRESS_SUCCESS,
  SET_GEN_ADDRESS_IS_COVERAGE,
  SET_SERVICE_INFO_DATA,
  SET_IS_IN_TIME_TO_DELIVERY,
} from '../../actions/actionTypes';

export const genAddressInitialState = {
  genAddress: {
    address1: null,
    address2: null,
    suite: null,
    zipCode: null,
  },
  isGenAddressCovered: true,
  genAddressErr: null,
  serviceInfo: {},
  isInTimeToDelivery: true,
};

export default function deliveryCustomerData(state = genAddressInitialState, action) {
  switch (action.type) {
    case SET_GEN_ADDRESS_SUCCESS:
      return {
        ...state,
        genAddress: action.payload,
      };
    case SET_GEN_ADDRESS_IS_COVERAGE:
      return {
        ...state,
        isGenAddressCovered: action.payload.isGenAddressCovered,
    };
    case SET_SERVICE_INFO_DATA:
      return {
        ...state,
        serviceInfo: action.payload,
    };
    case SET_IS_IN_TIME_TO_DELIVERY:
      return {
        ...state,
        isInTimeToDelivery: action.payload,
      };
    default:
      return state;
  }
}
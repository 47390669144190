import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import createSagaMiddleware from 'redux-saga';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createAppReducer from './reducers';
import sagas from './sagas';

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();
const initialState = {};
const enhancers = [];

const middleware = [
  routerMiddleware(history),
  sagaMiddleware,
];

const composedEnhancers = composeWithDevTools(
  applyMiddleware(...middleware),
  ...enhancers,
);

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['orderPlaced', 'payments', 'options', 'cart', 'delivery'],
};

const persistedReducer = persistReducer(persistConfig, createAppReducer(history));

const store = createStore(
  persistedReducer,
  // initialState,
  // loadFromLocalStorage(),
  composedEnhancers,
);

// store.subscribe(() => saveToLocalStorage(store.getState()));

sagaMiddleware.run(sagas);

export const persistor = persistStore(store);

export default store;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector} from 'react-redux';
import { Helmet } from 'react-helmet';
import { Switch, Route, useRouteMatch, useLocation, useParams } from 'react-router-dom';
import useOnclickOutside from 'react-cool-onclickoutside';

import ROUTES from '../../routes';
import { RestaurantOrderContext } from './context';
import TheRestaurantMenu from './TheRestaurantMenu/index';
import TheAppHeader from '../../components/Headers/TheAppHeader/index';
import Cart from './components/Cart/index';
import TheOrderCheckout from './TheOrderCheckout';
import PrivateRoute from '../../routes/PrivateRoute';
import ThankYou from './ThankYou';

import ModalNotCovered from './components/ModalNotCovered';
import ModalNotServiceTime from '../../components/Modals/ModalNotServiceTime';

import { CRUD_ACTIONS } from '../../const';
import {
  FETCH_GEN_ADDRESS_SAGA,
  FETCH_RESTAURANT_DATA_SAGA,
  UPDATE_MENU_ITEMS_CART_SAGA,
  FETCH_SERVICE_INFO_SAGA,
} from '../../redux/actions/actionTypes';


const RestaurantOrder = () => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [isShowCart, setIsShowCart] = useState(false);
  const [isNotInServiceTime, setIsNotInServiceTime] = useState(false);

  const { isMobile } = useSelector(state => state.options);
  const { menuItemsCart } = useSelector(state => state.order);
  const { restaurantData } = useSelector(state => state.restaurant);
  const { isGenAddressCovered, serviceInfo, genAddress, isInTimeToDelivery } = useSelector(state => state.delivery);

  const handleShowCart = (isShow) => {
    setIsShowCart(isShow);
  };

  const refOutsideClick = useOnclickOutside(() => {
    setIsShowCart(false);
  });

  const deleteMenuItemOrder = useCallback(
    (payload) => dispatch({
      type: UPDATE_MENU_ITEMS_CART_SAGA,
      payload: {
        action: CRUD_ACTIONS.DELETE,
        payload: payload,
      }
    }),
    [dispatch],
  );

  const fetchGenAddress = useCallback(
    (payload) => dispatch({ type: FETCH_GEN_ADDRESS_SAGA,  payload: {...payload, slug}  }),
    [dispatch],
  );

  useEffect(() => {
    dispatch({ type: FETCH_RESTAURANT_DATA_SAGA, payload: { slug } });
  }, []);

  useEffect(() => {
    if (genAddress.lat && genAddress.lng) {
      dispatch({type: FETCH_SERVICE_INFO_SAGA, payload: { lat: genAddress.lat, lng: genAddress.lng }});
    }
  }, [genAddress]);
  
  useEffect(() => {
    setIsNotInServiceTime(!isInTimeToDelivery);
  }, [isInTimeToDelivery]);

  return (
    <div className="pb-4 full">
      <RestaurantOrderContext.Provider
        value={{
          fetchGenAddress,
          menuItemsCart,
          restaurantData,
        }}
      >
        { !isGenAddressCovered ?
          <ModalNotCovered
            backupPartners={ restaurantData.backup_partners || [] }
            fetchGenAddress={ fetchGenAddress }
            isModalVisible={ !isGenAddressCovered }
          /> :
          <ModalNotServiceTime
            opensAt={ serviceInfo.opens_at }
            closesAt={ serviceInfo.closes_at }
            isModalVisible={ isNotInServiceTime }
            setIsModalVisible={ setIsNotInServiceTime }
          />
        }
        <TheAppHeader
          isMobile={isMobile}
          showCart={handleShowCart}
          isShowCart={isShowCart}
          fetchGenAddress={ fetchGenAddress }
          isShowCartBtn={ pathname.includes(ROUTES.ORDER.child.MENU.path) }
          disableCartBtn={ !isInTimeToDelivery }
          refOutsideClick={refOutsideClick}
          menuItemsCart={menuItemsCart}
        />
        <>
          <Cart
            isMobile={isMobile}
            isShow={isShowCart}
            handleShowCart={handleShowCart}
            refOutsideClick={refOutsideClick}
            menuItemsCart={menuItemsCart}
            deleteMenuItemOrder={deleteMenuItemOrder}
          >
          </Cart>
          <Switch>
            <Route path={`${path}/${ROUTES.ORDER.child.MENU.path}`}>
              <Helmet>
                <title>Coco App</title>
              </Helmet>
              <main className="app__main">
                <TheRestaurantMenu
                  disableCartBtn={ !isInTimeToDelivery }
                  handleShowCart={handleShowCart}
                  isMobile={isMobile}
                  menuItemsCart={menuItemsCart}
                />
              </main>
            </Route>
            <PrivateRoute
              component={TheOrderCheckout}
              path={`${path}/${ROUTES.ORDER.child.CHECKOUT.path}`} exact
            />
            <PrivateRoute component={ThankYou} path={`${path}/${ROUTES.ORDER.child.TANK_YOU.path}`}/>
          </Switch>
        </>
      </RestaurantOrderContext.Provider>
    </div>
  );
};

export default RestaurantOrder;

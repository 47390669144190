import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Button } from 'antd';

import Counter from '../../../../components/Form/Counter';
import Options from './Options';
import { priceFormatter } from '../../../../utils';
import { RestaurantMenuContext  } from '../../TheRestaurantMenu/context';

const { TextArea } = Input;

const MobileModalAddItem = (props) => {
  const { t } = useTranslation();
  const { quantity, increaseQuantity, decreaseQuantity, addOns } = useContext(RestaurantMenuContext);
  const { isModalVisible, instruction, updateInstruction, openedMenuItem, totalPrice } = props;
  // methods
  const { handleClose, handleAddToCart } = props;

  return (
    <Modal
      visible={ isModalVisible }
      onCancel={ handleClose }
      title={ null }
      style={ { height: `${window.innerHeight}px` }}
      closeIcon={ <span className="coco__icon close lg"/> }
      className="modal-menu mobile__modal_add_product mobile__full_screen_modal"
      footer={ null }
    >
      <h3 className="text-1-375x semi-bold" >{ openedMenuItem.name }</h3>
      <p className="app_medium_color mb-1 text-1x">{ openedMenuItem.description }</p>
      {
        openedMenuItem.menu_options &&
        <div className="extra__add mb-1">
          <div className="modal_add_header_block">
            <h4 className="mb-1 semi-bold text-sm"> { t('app.add-modal.add-extra') } </h4>
          </div>
          <div className="menu_option__wrapper app__scrolled">
            <Options
              menuOptions={openedMenuItem.menu_options}
              addOns={addOns}
            />
          </div>
        </div>
      }
      <div className="mobile_add__instruction">
        <p className="mb-1">
          { t('app.add-menu-item.add-instructions-mobile') }
        </p>
        <TextArea
          value={ instruction }
          onChange={ updateInstruction }
          className="text-1x mb-1"
          placeholder={ t('app.add-menu-item.add-instructions-placeholder') }
          autoSize={{ minRows: 2, maxRows: 3 }}
        />
      </div>
      <div className="count__wrapper">
        <Counter
          quantity={quantity}
          decrease={decreaseQuantity}
          increase={increaseQuantity}
        />
      </div>
      <div className='mobile__absolute_bottom__wrapper'>
        <Button
          type="primary" size="large"
          onClick={ handleAddToCart }
          className="action__button app__btn" block
        >
          <span>{ t('app.add-menu-item.add-to-cart') } </span>
          <span>{` ${priceFormatter(totalPrice)}`}</span>
        </Button>
      </div>
    </Modal>
  );
};

export default MobileModalAddItem;

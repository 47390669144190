import TokenService from '../storage/token';
/*
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instace.
*/
const AuthService = {
  isAuthenticated() {
    return TokenService.isAuthenticated();
  },
};

export default AuthService;

import BaseRepositoryError from "./base.errors";

import ApiService from "../../services/api";

const getApiResponse = async (requestData) => {
  try {
    const response = await ApiService.customJsonRequest(requestData);
    return response.data;
  } catch (error) {
    throw new BaseRepositoryError(error.response.status, error.response);
  }
};

const RestrictedRepository = {
  setOrderData: async (orderId, data) =>
    await getApiResponse({
      method: "put",
      url: `/v2/orders/restricted/${orderId}`,
      data,
    }),
};

export default RestrictedRepository;

export { RestrictedRepository, BaseRepositoryError };

import { Badge } from "antd";

import {
  CloseCircleFilled,
  CreditCardOutlined,
  IdcardOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import FileInput from "./FileInput";

import { ID_KEYS } from "../shared";

const IdSideUpload = ({ icon, name, onChange, reset, title, value }) => (
  <>
    {value ? (
      <Badge count={<CloseCircleFilled className="close" onClick={reset} />}>
        <div className="preview">
          <img alt={title} onChange={onChange} src={value} />
        </div>
      </Badge>
    ) : (
      <div className="bottom-spacing content">
        <FileInput
          alt="Camera"
          capture="environment"
          icon={icon}
          id={`captureUpload-${name}`}
          onChange={onChange}
          src={null}
          title={title}
        />
      </div>
    )}
  </>
);

const IdUpload = ({ onFileUpload, resetFileUpload, values }) => (
  <div className="full upload__wrapper">
    <IdSideUpload
      icon={
        values && values[ID_KEYS.frontLoading] ? (
          <LoadingOutlined />
        ) : (
          <IdcardOutlined />
        )
      }
      name="front"
      onChange={(e) =>
        onFileUpload(e, ID_KEYS.front, ID_KEYS.frontLoading, ID_KEYS.frontUrl)
      }
      reset={() => resetFileUpload([ID_KEYS.front, ID_KEYS.frontUrl])}
      title="Front of ID"
      value={
        values && ID_KEYS.front in values ? values[ID_KEYS.front] : undefined
      }
    />
    <IdSideUpload
      icon={
        values && values[ID_KEYS.backLoading] ? (
          <LoadingOutlined />
        ) : (
          <CreditCardOutlined />
        )
      }
      name="back"
      onChange={(e) =>
        onFileUpload(e, ID_KEYS.back, ID_KEYS.backLoading, ID_KEYS.backUrl)
      }
      reset={() => resetFileUpload([ID_KEYS.back, ID_KEYS.backUrl])}
      title="Back of ID"
      value={
        values && ID_KEYS.back in values ? values[ID_KEYS.back] : undefined
      }
    />
  </div>
);

export default IdUpload;

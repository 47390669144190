import { all, fork } from 'redux-saga/effects';
import watchUserAddPhone from './watchUserAddPhone';
import watchUserAddPhoneCodeVerify from './watchUserAddPhoneCodeVerify';
import watchUserData from './watchUserData';
import watchUserUpdateData from './watchUserUpdateData';

function* rootUser() {
  yield all([
    fork(watchUserAddPhone),
    fork(watchUserAddPhoneCodeVerify),
    fork(watchUserData),
    fork(watchUserUpdateData),
  ]);
}

export default rootUser;
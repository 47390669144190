import { put, takeLatest, call, select } from "redux-saga/effects";
import { push } from 'connected-react-router';
import { message } from 'antd';
import BaseRepositoryError from '../../repositories/base.errors';
import UserRepository from '../../repositories/user';

import {
  FETCH_ADD_PHONE_CODE_VERIFY_SAGA,
  FETCH_DATA_404, FETCH_USER_DATA_SAGA,
  SET_DATA_ERROR_MSG, SET_USER_DATA
} from '../../actions/actionTypes';
import { HandleSuccessRouteRedirect } from '../auth/mixins';

function* workerFetchAddPhoneCodeVerify(action) {
  try {
    const userData = yield select(state => state.user.userData);
    const response = yield call(UserRepository.addPhoneCodeVerify, userData.id, { code: action.payload.code });
    if (response.success) {
      // set manual phone_verified for private router checker
      yield put ({ type: SET_USER_DATA, payload: { ...userData, phone_verified: true } });
      yield* HandleSuccessRouteRedirect();
      yield call(message.success(response.message, 2000));
      yield put({ type: FETCH_USER_DATA_SAGA});
    } else {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: response.message });
    }
  } catch (e) {
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* watchUserAddPhoneCodeVerify() {
  yield takeLatest(FETCH_ADD_PHONE_CODE_VERIFY_SAGA, workerFetchAddPhoneCodeVerify);
}

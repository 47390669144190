import { Result } from 'antd';
import { FrownOutlined } from '@ant-design/icons';

const NotFoundResult = (props) => {
  const { title } = props;
  return (
    <Result
      icon={<FrownOutlined />}
      title={title || '404 Not found'}/>
  );
};

export default NotFoundResult;


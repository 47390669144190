import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Spin, message, Col, Select, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { FETCH_ADD_PHONE_SAGA } from '../../redux/actions/actionTypes';

const AddPhone = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formLayout, setFormLayout] = useState('vertical');
  const [loading, setLoading] = useState(false);

  const { userData } = useSelector(state => state.user);
  const { errorMsg } = useSelector(state => state.errors);
  
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  
  useEffect(() => {
    if (errorMsg) {
      setLoading(false);
    }
  }, [errorMsg]);

  const fetchAddPhone = useCallback(
    (payload) => dispatch({ type: FETCH_ADD_PHONE_SAGA,  payload }),
    [dispatch],
  );

  const onFinish = (values) => {
    fetchAddPhone(values);
    setLoading(true);
  };

  return(
    <div>
      <Spin spinning={loading} indicator={antIcon}>
      <div className="auth_card__wrap mb-2">
        <h1 className="text-center">
          { t('auth.add_phone-header') }
        </h1>
      </div>
      <div className="auth_card__wrap">
        <Form
          className="auth__form"
          layout={formLayout}
          size={'large'}
          initialValues={{
            phoneCode: (userData && userData.phone) ? userData.phone.slice(0, 2) : '+1',
            phone: (userData && userData.phone) && userData.phone.slice(2),
          }}
          onFinish={onFinish}
        >
          <Row gutter={16}>
            <Col span={7} sm={{ order: 7 }} md={{ order: 7 }}>
              <Form.Item name={'phoneCode'} label="Country" rules={[{ required: true }]}>
                <Select value="+1">
                  <Select.Option value="+1">+1(US)</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={17} sm={{ order: 17 }} md={{ order: 17 }}>
              <Form.Item name={'phone'} label="Mobile Number">
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label=" " colon={false}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="auth_btn app__btn text-upper" block
            >
              { (userData && userData.phone) ? t('auth.add_phone_verify') : t('auth.add_phone') }
            </Button>
          </Form.Item>
        </Form>
      </div>
      </Spin>
    </div>
  );
};

export default AddPhone;
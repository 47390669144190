import { useTranslation } from 'react-i18next';
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Switch, Route, useLocation, Link, useRouteMatch } from 'react-router-dom';

import AuthHeader from '../../components/Headers/AuthHeader';
import AuthCardTabs from './components/AuthCardTabs';
import SignIn from './SignIn';
import SignUp from './SignUp';
import AddPhone from './AddPhone';
import AddPhoneCodeVerify from './AddPhoneCodeVerify';
import ResetPassword from './ResetPassword';
import ResetPasswordSent from './ResetPasswordSent';

import ROUTES from '../../routes';

const Auth = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { path } = useRouteMatch();
  const { restaurantSlug } = useSelector(state => state.root);

  return (
    <div className="full">
      <AuthHeader restaurantSlug={restaurantSlug} />
      <div className="auth__wrapper">
        <div className="auth_card__wrapper">
          <div className="mb-2">
            <AuthCardTabs
              location={location}
              signInPath={`${path}/${ROUTES.AUTH.child.SIGN_IN.path}`}
              signUpPath={`${path}/${ROUTES.AUTH.child.SIGN_UP.path}`}
              signInKey={ROUTES.AUTH.child.SIGN_IN.path}
              signUpKey={ROUTES.AUTH.child.SIGN_UP.path}
            />
          </div>
          <Switch>
            <Route path={`${path}/${ROUTES.AUTH.child.SIGN_IN.path}`}>
              <Helmet>
                <title>{ t('auth.sign_in') }</title>
              </Helmet>
              <SignIn/>
            </Route>
            <Route path={`${path}/${ROUTES.AUTH.child.SIGN_UP.path}`}>
              <Helmet>
                <title>{ t('auth.sign_up') }</title>
              </Helmet>
              <SignUp/>
            </Route>
            <Route path={`${path}/${ROUTES.AUTH.child.ADD_PHONE.path}`}>
              <Helmet>
                <title>{ t('auth.add_phone') }</title>
              </Helmet>
              <AddPhone/>
            </Route>
            <Route path={`${path}/${ROUTES.AUTH.child.ADD_PHONE_VERIFY.path}`}>
              <Helmet>
                <title>{ t('auth.add_phone_verify') }</title>
              </Helmet>
              <AddPhoneCodeVerify/>
            </Route>
            <Route path={`${path}/${ROUTES.AUTH.child.RESET_PASSWORD.path}`}>
              <Helmet>
                <title>{ t('auth.reset-password') }</title>
              </Helmet>
              <ResetPassword/>
            </Route>
            <Route path={`${path}/${ROUTES.AUTH.child.RESET_PASSWORD_SENT.path}`}>
              <Helmet>
                <title>{ t('auth.reset-password') }</title>
              </Helmet>
              <ResetPasswordSent/>
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Auth;

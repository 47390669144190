import { put, takeLatest, call, select } from "redux-saga/effects";
import BaseRepositoryError from '../../repositories/base.errors';
import AuthRepository from '../../repositories/auth';
import { getAuthSuccessRouter } from '../../selectors/routers';

import {
  FETCH_LOGIN_OAUTH_SAGA, FETCFETCH_ADD_PHONE_SAGAH_DATA_404,
  SET_DATA_ERROR_MSG, SET_USER_DATA, FETCH_ADD_PHONE_SAGA,
} from '../../actions/actionTypes';
import { push } from "connected-react-router";
import ROUTES from '../../../routes';
import { handleAddUserAddressToDelivery, HandleSuccessRouteRedirect } from './mixins';

function* workerFetchSignOauthIn(action) {
  try {
    console.log(action.payload);
    const response = yield call(AuthRepository.loginOauth, action.payload);
    yield put({ type: SET_USER_DATA, payload: response.user });
    let successRouter = yield select(getAuthSuccessRouter);
    if (response && response.user) {
      // check for
      successRouter = yield* handleAddUserAddressToDelivery(response.user.address, successRouter);
      if (!response.user.phone_verified) {
        yield put(push(
          { pathname: `${ROUTES.AUTH.path}/${ROUTES.AUTH.child.ADD_PHONE.path}`, state: { successRouter } }
        ));
      } else {
        yield* HandleSuccessRouteRedirect(successRouter);
      }
    } else {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: 'please try again'});
    }
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* FetchSignInOauthSaga() {
  yield takeLatest(FETCH_LOGIN_OAUTH_SAGA, workerFetchSignOauthIn);
}

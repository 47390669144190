import { put, takeLatest, call, select } from "redux-saga/effects";
import { push } from 'connected-react-router';
import { message } from 'antd';
import BaseRepositoryError from '../../repositories/base.errors';
import UserRepository from '../../repositories/user';
import { getAuthSuccessRouter } from '../../selectors/routers';

import {
  FETCH_USER_DATA_SAGA,
  FETCH_DATA_404,
  SET_USER_DATA,
  SET_DATA_ERROR_MSG
} from '../../actions/actionTypes';
import ROUTES from '../../../routes';

function* workerFetchUserData(action) {
  const userData = yield select(state => state.user.userData);
  try {
    const id = userData.id;
    const response = yield call(UserRepository.fetchUser, id);
    yield put ({ type: SET_USER_DATA, payload: response });
  } catch (e) {
    console.debug(e);
    // if (e instanceof BaseRepositoryError) {
    //   // yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
    //   // if (e.errorCode === 404) {
    //   //   yield put({ type: FETCH_DATA_404 });
    //   // }
    // }
  }
}

export default function* watchUserData() {
  yield takeLatest(FETCH_USER_DATA_SAGA, workerFetchUserData);
}

import logoImg from '../../../assets/logo.png';
import LogoBox from '../LogoBox.jsx';

import { Avatar, Dropdown, Menu } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LocationWrapper from '../LocationWrapper';
import ROUTES from '../../../routes';


const MobileHeader = (props) => {
  const { t } = useTranslation();
  const {
    isAuthenticated, signInPath, signUpPath, authSuccessRouter,
    fetchLogout, restaurantSlug, fetchGenAddress, isShowCartBtn,
    basic, noBorder
  } = props;
  const { address1, address2 } = props.genAddress;

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={{ pathname: `${ROUTES.PROFILE.path}/${ROUTES.PROFILE.child.UPDATE.path}` }}>
          { t('profile.menu-list.update-profile') }
        </Link>
      </Menu.Item>
      <Menu.Item>
        <a onClick={ () => fetchLogout() }>
          { t('profile.menu-list.logout') }
        </a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className="app_header">
      <div className={`app_header__wrapper${noBorder ? " no-border" : ""}${basic ? " left-aligned" : ""}`}>
        <div className="app_header__wrap">
          <Link to={{ pathname: `${ROUTES.ORDER.link_path(restaurantSlug)}/${ROUTES.ORDER.child.MENU.path}`}}>
            <LogoBox logoImg={logoImg}/>
          </Link>
        </div>
        {!basic &&
          <div className="app_header__auth">
            { isAuthenticated ?
              <Dropdown overlay={ menu } trigger={['click']}>
                <Avatar  className="user_header__icon" icon={ <UserOutlined/> }/>
              </Dropdown> :
              <>
                <Link className="primary__link" to={  {pathname: signInPath, state: {authSuccessRouter}} }>
                  { t('auth.log_in') }
                </Link>
                <Link
                  to={ {pathname: signUpPath, state: {authSuccessRouter}} }
                  type="primary" size="large" className="action__button sign-up"
                >
                  { t('auth.sign_up') }
                </Link>
              </>
            }
          </div>
        }
      </div>
      { isShowCartBtn &&
        <LocationWrapper
          className={ 'app_header_mobile__location' }
          fetchGenAddress={ fetchGenAddress }
          address={ `${ address1 }, ${ address2 }` }
          isMobile={ true }
        />
      }
    </header>
  );
};

export default MobileHeader;

import ApiService from "../../services/api";
import BaseRepositoryError from "./base.errors";

const OrderRepository = {
  cart: async (payload) => {
    const defaultData = {
      order_type: "restaurant",
      source: "web",
    };
    const { userData, addressData, menuItemsData, payloadsData } = payload;
    const requestData = {
      method: "post",
      url: "/orders/cart",
      data: {
        ...userData,
        ...addressData,
        ...defaultData,
        ...menuItemsData,
        ...payloadsData,
      },
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  cartWithPromo: async (payload) => {
    const defaultData = {
      created_at: new Date().getTime(),
      order_type: "restaurant",
      source: "web",
      final_price: 10000,
    };
    const { userData, addressData, menuItemsData, payloadsData } = payload;
    const requestData = {
      method: "post",
      url: "/promo/v2/apply",
      data: {
        ...userData,
        ...addressData,
        ...defaultData,
        ...menuItemsData,
        ...payloadsData,
      },
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  placeOrder: async (payload) => {
    const defaultData = {
      order_type: "storefront",
      source: "coco_web_storefront",
    };
    const { userData, addressData, menuItemsData, payloadsData } = payload;
    const requestData = {
      method: "post",
      url: "/v2/orders/place",
      data: {
        ...userData,
        ...addressData,
        ...defaultData,
        ...menuItemsData,
        ...payloadsData,
      },
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  getOrderData: async (orderId) => {
    const requestData = {
      method: "get",
      url: `/v2/orders/${orderId}`,
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default OrderRepository;

export { OrderRepository, BaseRepositoryError };

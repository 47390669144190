import { put, takeEvery, call, select } from 'redux-saga/effects';
import BaseRepositoryError from '../../repositories/base.errors';
import RestaurantRepository from '../../repositories/restaurant';

import {
  FETCH_RESTAURANT_DATA_SAGA,
  SET_RESTAURANT_DATA,
  FETCH_RESTAURANT_DATA_SAGA_FINISH,
  FETCH_DATA_404,
  SET_SLUG_RESTAURANT,
} from '../../actions/actionTypes';

function* workerFetchRestaurantData(action) {
  try {
    const { genAddress } = yield select(state => state.delivery);
    let locationPayload = {};
    if (genAddress) {
      locationPayload = {
        lat: genAddress.lat,
        lng: genAddress.lng,
      };
    }
    yield put({ type: SET_SLUG_RESTAURANT, payload: action.payload.slug });
    const response = yield call(
      RestaurantRepository.getRestaurantData,
      { slug: action.payload.slug, ...locationPayload }
    );
    yield put({type: SET_RESTAURANT_DATA, payload: {
      ...response,
      slug: action.payload.slug,
    }});
    yield put({ type: FETCH_RESTAURANT_DATA_SAGA_FINISH });
  } catch (e) {
    if (e instanceof BaseRepositoryError) {
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* watchRestaurantSaga() {
  yield takeEvery(FETCH_RESTAURANT_DATA_SAGA, workerFetchRestaurantData);
}

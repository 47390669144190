export const userDataForOrderApi = (state, key_prefix = "") => {
  /*
    "address": {
        "address1": "3104 4th St",
        "address2": "Santa Monica, CA 90405, US",
        "location": {
            "lat": 33.9994115,
            "lng": -118.4773178
        },
        "suite": "Apt 101"
    },
  */
  const { userData } = state.user;
  return {
    [`${key_prefix}first_name`]: userData.first_name,
    [`${key_prefix}last_name`]: userData.last_name,
    [`${key_prefix}phone`]: userData.phone,
    user_id: userData.id,
  };
};

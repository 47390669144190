export const menuItemsForOrderApi = (state) => {
  /*
    "items": [
        {
            "quantity": 1,
            "menu_options": {
                "Type of Cheese": {
                    "value": "Sharp Cheddar"
                },
                "Pickles1": {
                    "value": true
                }
            },
            "instruction": "",
            "id": "5ff50ba213368d6c9fc8462a",
            "name": "Cheesburger"
        }

    ],
  */
  const { menuItemsCart } = state.order;
  const apiItems = menuItemsCart.reduce((acc, el) => {
    let children = [];
    if (el.addOns) {
      children = Object.entries(el.addOns).reduce((acc, [key, addObj]) => {
        acc[key] = { value: key === addObj.name || addObj.name };
        return acc;
      }, {});
    }
    acc.push({
      id: el.id,
      name: el.name,
      quantity: el.quantity,
      menu_options: children,
      instruction: el.instruction,
      price: el.price,
    });
    return acc;
  }, []);

  return {
    items: apiItems,
  };
};

import { Button } from 'antd';

const Counter = (props) => {
  const { decrease, increase, quantity } = props;

  return (
    <div className="counter">
      <Button
        type="primary"
        className="count__btn"
        onClick={() => decrease()}
        icon={'-'}
      />
      <span>{ quantity }</span>
      <Button
        type="primary"
        className="count__btn"
        onClick={() => increase()}
        icon={'+'}
      />
    </div>
  );
};

export default Counter;
import ApiService from '../../services/api';
import TokenService from '../../services/storage/token';
import BaseRepositoryError from './base.errors';

const StripeRepository = {
  createPaymentIntent: async (payload) => {
    const defaultData = {
      order_type: 'restaurant',
      source: 'web',
    };
    const { userData, addressData, menuItemsData, payloadsData} = payload;
    const requestData = {
      method: 'post',
      url: '/stripe/create-payment-intent',
      data: {
        ...userData,
        ...addressData,
        ...defaultData,
        ...menuItemsData,
        ...payloadsData,
      },
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default StripeRepository;

export { StripeRepository, BaseRepositoryError };

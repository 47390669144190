import { all, fork } from "redux-saga/effects";

import watchRestaurantMenuSaga from './watchOrderMenuItemsSaga';
import watchOrderCartTotal from './watchOrderCartTotal';
import watchOrderPlace from './watchOrderPlace';
import watchOrderCartTotalPromo from './watchOrderCartTotalPromo';
import watchOrderData from './watchOrderData';

function* rootOrder() {
  yield all([
    fork(watchRestaurantMenuSaga),
    fork(watchOrderCartTotal),
    fork(watchOrderPlace),
    fork(watchOrderCartTotalPromo),
    fork(watchOrderData),
  ]);
}

export default rootOrder;

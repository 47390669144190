import ApiService from '../../services/api';
import BaseRepositoryError from './base.errors';

const RestaurantRepository = {

  async getRestaurantData(payload) {
    /*
     * getRestaurantData
     * @returns restaurantData object
     * @throws BaseRepositoryError
    */
    const params = {
      ...payload.lat && { latitude: payload.lat },
      ...payload.lng && { longitude: payload.lng }
    };

    const requestData = {
      method: 'get',
      url: `/restaurants/${payload.slug}`,
      params,
    };

    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  async getRestaurantMenu(payload) {
    /*
     * getRestaurantMenu
     * @returns restaurantMenu list objects
     * @throws BaseRepositoryError
    */

    const requestData = {
      method: 'get',
      url: `/restaurants/${payload.restaurantId}/menu`,
    };

    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default RestaurantRepository;
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const AuthCardTabs = (props) => {
  const { t } = useTranslation();
  const { location, signInPath, signUpPath, signInKey, signUpKey } = props;
  
  const IsActiveClass = (name) => {
    if (location) {
      return location.pathname.includes(name) ? 'active' : '';
    }
    return '';
  };

  return (
    <div className="auth_card__tab">
      <div className="auth_card__tab_wrapper">
        <div className="auth_card__tab_wrap">
          <Link
            to={signInPath}
            className={`head__text app__text_color ${IsActiveClass(signInKey)}`}
          >
            { t('auth.sign_in') }
          </Link>
        </div>
        <div className="auth_card__tab_wrap">
          <Link
            to={signUpPath}
            className={`head__text app__text_color ${IsActiveClass(signUpKey)}`}
          >
            { t('auth.sign_up') }
          </Link>
        </div>
      </div>
    </div>
  );
};

export default AuthCardTabs;
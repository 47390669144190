import { useState, useEffect } from 'react';
import { Modal } from 'antd';
import PlacesAutocomplete from '../../../components/Form/PlacesAutocomplete';
import { backUpPartnersImg } from '../../../const';

import postmates from '../../../assets/backup_partners/postmates.png';
import doordash from '../../../assets/backup_partners/doordash.png';
import ubereats from '../../../assets/backup_partners/ubereats.png';
import orderfor from '../../../assets/backup_partners/orderfor.png';
import { useTranslation } from 'react-i18next';

const ModalNotCovered = (props) => {
  const { t } = useTranslation();
  const { isModalVisible, backupPartners } = props;
  const [retriedWithNewAddress, setRetriedWithNewAddress] = useState(false);

  const partnerImgs = {
    postmates,
    doordash,
    ubereats,
    orderfor
  };

  const placesHandler = (payload) => {
    // fetchGenAddress with google placesHandler list
    setRetriedWithNewAddress(true);
    props.fetchGenAddress(payload);
  };

  return (
    <Modal
      visible={isModalVisible}
      footer={null}
      title={null}
      closable={false}
      width={650}
      className="modal_ncovered"
    >
      <div className="modal_ncovered__boby">
        <h3 className="text-1-375x semi-bold text-center mb-1">
          {  t('app.modal-not-covered.header') }
        </h3>
        <div className="app_medium_color text-center mb-1 text-center mt-1 mb-1 text-1x">
          { retriedWithNewAddress && isModalVisible ?
            <span className="app_warning_color">{ t('app.modal-not-covered.subheader') }</span> :
            <span>{ t('app.modal-not-covered.not-cover-retried') }</span>
          }
        </div>
          <div className="mt-1 mb-1">
            <PlacesAutocomplete
              className="base_app__autocomplete" onPlaceGet={ placesHandler }
            />
          </div>
        <p className="app_medium_color text-center mb-1 text-1x">
          { t('app.modal-not-covered.or-use') }
        </p>
        <div className="backup__partners">
          {
            backupPartners.map((el, elInd) => (
              <a key={`img__${elInd}`} href={el.url} target='_blank'>
                <div
                  className="backup__partners__item"
                  style={{ backgroundImage: `url(${partnerImgs[el.partner]})`}}
                />
              </a>
            ))
          }
        </div>
      </div>
    </Modal>
  );
};

export default ModalNotCovered;

import { put, takeLatest, call } from 'redux-saga/effects';

import BaseRepositoryError from '../../repositories/base.errors';
import ServiceRepository from '../../repositories/service';

import {
  SET_DATA_ERROR_MSG,
  FETCH_SERVICE_INFO_SAGA,
  SET_SERVICE_INFO_DATA,
  SET_IS_IN_TIME_TO_DELIVERY
} from '../../actions/actionTypes';

const isOpenForDelivery = (serviceInfoData) => {
  const { opens_at, closes_at } = serviceInfoData;
  const getMinutes = (dateValue) => {
    return dateValue.getHours() * 60 + dateValue.getMinutes();
  };
  const nowTime = new Date();
  const openTime = new Date(opens_at);
  const closesTime = new Date(closes_at);
  return getMinutes(nowTime) > getMinutes(openTime) && getMinutes(nowTime) < getMinutes(closesTime);
};

function* workerFetchServiceInfo(action) {
  const NotInServiceRegion = 'NotInServiceRegion';
  try {
    const serviceInfoData = yield call(ServiceRepository.getServiceInfo, action.payload);
    if (serviceInfoData.error) {
      if (serviceInfoData.error === NotInServiceRegion) {
        yield put({type: SET_GEN_ADDRESS_IS_COVERAGE, payload: false});
      } else {
        yield put({ type: SET_DATA_ERROR_MSG, payload: serviceInfoData.error });
      }
    }
    yield put({ type: SET_SERVICE_INFO_DATA, payload: serviceInfoData });
    yield put({ type: SET_IS_IN_TIME_TO_DELIVERY, payload: isOpenForDelivery(serviceInfoData) });
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put({ type: SET_DATA_ERROR_MSG, payload: e.message });
    }
  }
}

export default function* watchFetchServiceInfo() {
  yield takeLatest(FETCH_SERVICE_INFO_SAGA, workerFetchServiceInfo);
}
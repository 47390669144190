import "../../styles/id_verification.less";

import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useParams } from "react-router-dom";

import DatePicker from "./components/DatePicker";
import IdUpload from "./components/IdUpload";
import VerificationPage from "./components/VerificationPage";

import { ID_KEYS } from "./shared";

import TheAppHeader from "../../components/Headers/TheAppHeader";
import FirebaseService from "../../services/firebase";

import {
  FETCH_ORDER_DATA_SAGA,
  FETCH_UPDATE_RESTRICTED_ORDER_DATA,
} from "../../redux/actions/actionTypes";
import TokenService from "../../services/storage/token";

const IdVerificationStep = { Terms: 0, DoB: 1, Upload: 2, WaitingRoom: 3 };

const IdVerification = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: "(max-width: 758px)" });
  const { orderId } = useParams();
  const { orderUpdate } = useSelector((state) => state.restrictedCheckout);
  const { orderPlacedData } = useSelector((state) => state.orderPlaced);

  const [accessToken, setAccessToken] = useState();
  const [currStep, setCurrStep] = useState(0);
  const [state, setState] = useState({});

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    setAccessToken(params.get("access_token"));
  }, []);

  useEffect(() => {
    if (accessToken && orderId) {
      TokenService.saveToken(accessToken);
      
      setState((prevState) => ({ ...prevState, orderId }));
      dispatch({ type: FETCH_ORDER_DATA_SAGA, payload: orderId });
    }
  }, [accessToken, orderId]);

  useEffect(() => {
    if (
      orderPlacedData &&
      orderPlacedData.orderId === orderId &&
      orderPlacedData.restricted_checkout_history
    ) {
      const restrictedCheckoutData =
        orderPlacedData.restricted_checkout_history;

      let newState = {
        agreedToTerms: restrictedCheckoutData.agreed_to_terms,
        dateOfBirth: restrictedCheckoutData.date_of_birth
          ? new Date(restrictedCheckoutData.date_of_birth)
          : null,
        enteredDoB: !!restrictedCheckoutData.date_of_birth,
        needToUpload: restrictedCheckoutData.need_id_upload,
        zoomLink: restrictedCheckoutData.customer_zoom_link,
      };

      if (!newState.needToUpload) {
        newState = {
          ...newState,
          enteredBackId: !!restrictedCheckoutData.back_id,
          enteredFrontId: !!restrictedCheckoutData.front_id,
          idUploads: {
            [ID_KEYS.backUrl]: restrictedCheckoutData.back_id,
            [ID_KEYS.frontUrl]: restrictedCheckoutData.front_id,
          },
        };
      }

      setState((prevState) => ({ ...prevState, ...newState }));
    }
  }, [orderPlacedData]);

  useEffect(() => {
    if (
      orderUpdate &&
      orderUpdate.message &&
      orderUpdate.message.toLowerCase() !== "success"
    ) {
      alert("Failed to update order with your ID details");
    }
  }, [orderUpdate]);

  useEffect(() => {
    let nextStep = IdVerificationStep.WaitingRoom;
    if (state) {
      if (!state.agreedToTerms) {
        nextStep = IdVerificationStep.Terms;
      } else if (!state.dateOfBirth || !state.enteredDoB) {
        nextStep = IdVerificationStep.DoB;
      } else if (
        !state.idUploads ||
        !state.idUploads[ID_KEYS.backUrl] ||
        !state.enteredBackId ||
        !state.idUploads[ID_KEYS.frontUrl] ||
        !state.enteredFrontId
      ) {
        nextStep = IdVerificationStep.Upload;
      }
    }

    setCurrStep(nextStep);
  }, [state]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const getUploadFilename = (key) => `${orderId}-${key}.png`;

  const olderThan21 = () => {
    if (!state.dateOfBirth) {
      return false;
    }

    const now = new Date();

    const msDiff = Date.parse(now) - state.dateOfBirth.getTime();
    const age = new Date(msDiff);

    return Math.abs(age.getUTCFullYear() - 1970) >= 21;
  };

  const onBack = () => {
    const newStep = currStep - 1;
    switch (newStep) {
      case IdVerificationStep.DoB:
        setState((prevStep) => ({ ...prevStep, enteredDoB: false }));
        break;
      case IdVerificationStep.Upload:
        setState((prevStep) => ({
          ...prevStep,
          enteredBackId: false,
          enteredFrontId: false,
        }));
        break;
      default:
        break;
    }

    setCurrStep(newStep);
  };

  const onFileUpload = (e, key, loadingKey, urlKey) => {
    if (e.target.files && e.target.files.length) {
      const file = e.target.files[0];

      const filename = getUploadFilename(key);
      const uploadDest = `gs://coco-restricted/${filename}`;

      const idUploads = state.idUploads || {};
      idUploads[loadingKey] = true;
      setState({ ...state, idUploads });

      FirebaseService.upload(uploadDest, file)
        .then((d) => {
          getBase64(e.target.files[0])
            .then((data) => {
              setState((prev) => {
                const idUploads = prev.idUploads || {};
                idUploads[key] = data;
                idUploads[loadingKey] = false;
                idUploads[urlKey] = filename;
                return { ...prev, idUploads };
              });
            })
            .catch(console.error);
        })
        .catch(console.error);
    }
  };

  const resetFileUpload = (keys) => {
    const idUploads = state.idUploads || {};
    keys.forEach((key) => delete idUploads[key]);
    setState({ ...state, idUploads });
  };

  const renderCurrStep = () => {
    switch (currStep) {
      case IdVerificationStep.Terms:
        return (
          <VerificationPage
            hasRobot
            buttonOnClick={() => setState({ ...state, agreedToTerms: true })}
            buttonText="Agree & Continue"
            canContinue={
              !!orderPlacedData && !!orderPlacedData.restricted_checkout_history
            }
            linkText="View Terms & Conditions"
            linkUrl="https://www.cocodelivery.com/terms"
            subtitle="Alcohol Delivery Verification"
            text="ID Verification done via Zoom Call after order is placed. Failure to attend video call will result in the cancellation of your order. Be ready to have your ID Present."
            textClasses={["mini"]}
            title="Your order will be fulfilled by a Coco Delivery Robot"
          />
        );
      case IdVerificationStep.DoB:
        return (
          <VerificationPage
            buttonClasses={["all-caps"]}
            buttonOnClick={() => setState({ ...state, enteredDoB: true })}
            buttonText="Next"
            canContinue={olderThan21()}
            onBack={onBack}
            renderContent={() => (
              <DatePicker
                onChange={(dateOfBirth) => setState({ ...state, dateOfBirth })}
                value={state.dateOfBirth}
              />
            )}
            subtitle="Please Enter Your Birth Date (Must Match Photo ID)"
            title="Alcohol Delivery Verification"
          />
        );
      case IdVerificationStep.Upload:
        return (
          <VerificationPage
            buttonClasses={["all-caps"]}
            buttonOnClick={() => {
              setState({ ...state, enteredBackId: true, enteredFrontId: true });
              dispatch({
                type: FETCH_UPDATE_RESTRICTED_ORDER_DATA,
                payload: {
                  agreed_to_terms: state.agreedToTerms,
                  back_id: state.idUploads[ID_KEYS.backUrl],
                  date_of_birth: state.dateOfBirth,
                  front_id: state.idUploads[ID_KEYS.frontUrl],
                  need_id_upload: false,
                  orderId,
                },
              });
            }}
            buttonText="Next"
            canContinue={
              state.idUploads &&
              state.idUploads[ID_KEYS.backUrl] &&
              state.idUploads[ID_KEYS.frontUrl]
            }
            onBack={onBack}
            renderContent={() => (
              <IdUpload
                onFileUpload={onFileUpload}
                resetFileUpload={resetFileUpload}
                values={state.idUploads}
              />
            )}
            subtitle="Please take photos of your ID"
            title="ID Verification"
          />
        );
      case IdVerificationStep.WaitingRoom:
      default:
        return (
          <VerificationPage
            hasRobot
            buttonClasses={["link"]}
            buttonOnClick={() => window.open(state.zoomLink)}
            buttonText="Enter Zoom Meeting"
            canContinue={!!state.zoomLink}
            onBack={onBack}
            subtitle="Age Verification"
            text="Please have your valid photo ID ready to show our Customer Success Agent."
            title="Please wait, an agent will be with you shortly"
          />
        );
    }
  };

  return (
    <div className="center-col">
      <Helmet>
        <title>Coco | ID Verification</title>
      </Helmet>
      <TheAppHeader basic noBorder isMobile={isMobile} />
      {renderCurrStep()}
    </div>
  );
};

export default IdVerification;

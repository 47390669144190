import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useParams } from "react-router-dom";
import { Row, Col, Button } from 'antd';

import { COST_FEE } from '../../../const';
import { priceFormatter, secondsToStringTime } from '../../../utils';

import ModalAddItem from '../components/ModalAddItem/index';
import MenuCard from '../components/MenuCard';
import MobileMenuCard from '../components/MobileMenuCard';

import {
  FETCH_RESTAURANT_MENU_DATA_SAGA, FETCH_RESTAURANT_DATA_SAGA,
  FETCH_GEN_ADDRESS_SAGA, UPDATE_MENU_ITEMS_CART_SAGA
} from '../../../redux/actions/actionTypes';
import { selectMenuGroupedByCategory } from '../../../redux/selectors/restaurant';

const TheRestaurantMenu = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const { isMobile, handleShowCart, menuItemsCart, disableCartBtn } = props;
  const { t } = useTranslation();

  const [isModalAddItemVisible, setIsModalAddItemVisible] = useState();
  const [openedMenuItem, setOpenedMenuItem] = useState({});

  const menuGroupedByCategory = useSelector(selectMenuGroupedByCategory);
  const { restaurantData, menuCategories, menu } = useSelector(state => state.restaurant);

  useEffect(() => {
    dispatch({ type: FETCH_RESTAURANT_MENU_DATA_SAGA, payload: { slug } });
  }, [restaurantData]);

  const updateMenuItemOrder = useCallback(
    (payload) => dispatch({ type: UPDATE_MENU_ITEMS_CART_SAGA,  payload }),
    [dispatch],
  );

  const getMenuItemByInd = (itemId) => {
    const getMenuItem = () => (
      menu.find((el) => ( el.id === itemId)
    ));
    setIsModalAddItemVisible(true);
    setOpenedMenuItem(getMenuItem());
  };

  return (
    <div className="menu__wrapper container">
      <ModalAddItem
        isMobile={ isMobile }
        addToCart={ updateMenuItemOrder }
        isModalVisible={ isModalAddItemVisible }
        onClose={() => { setIsModalAddItemVisible(false); }}
        openedMenuItem={openedMenuItem}
      />
      {/* MENU HEADER */}
      <Row className="menu__delivery__wrapper">
        <Col xs={24} md={20}>
          <div className="menu__delivery_price_wrap">
            <span className="color_app__primary text-sx">{ priceFormatter(COST_FEE) } DELIVERY</span>
          </div>
          <div className="menu__name_wrap">
            <h1 className="text-1-75x">{ restaurantData.name }</h1>
          </div>
          <div className="menu__delivery_data_wrap text-sm">
            <span className="data_part">
              <span className="coco__icon time sm color_divider_app__icon"/>
              <span>{ secondsToStringTime(restaurantData.delivery_eta) }</span>
            </span>
            <span className="data_part">
              <span className="coco__icon location sm color_divider_app__icon"/>
              <span>{ restaurantData.address }</span>
            </span>
          </div>
        </Col>
        { !isMobile &&
          <Col xs={24} md={4} className="restaurant__logo__wrapper">
            { restaurantData.logo_img &&
            <img src={ restaurantData.logo_img } alt="COCO"/>
            }
          </Col>}
      </Row>
      {/* MOBILE HANDLER */}
      <div className="menu__items__wrapper">
        { menuCategories && menuCategories.map((elCategory, elCategoryInd) => {
          return (
            <div key={`menu_item_${elCategoryInd}`}>
              <Row key={`category_${elCategoryInd}`} className="category__row">
                <h2 className="menu__category_header text-1-5x color_app__primary">
                  {elCategory}
                </h2>
              </Row>
              <Row key={`menu_row_${elCategoryInd}`} className="mb-1">
                {/* MOBILE HANDLER */}
                { menuGroupedByCategory[elCategory] && menuGroupedByCategory[elCategory].map((elMenu, elMenuInd) => (
                  <Col xs={24} md={12} key={`menu_card_${elMenuInd}`}>
                    {isMobile ?
                      <MobileMenuCard
                        onAdd={() => getMenuItemByInd(elMenu.id)}
                        name={elMenu.name}
                        description={elMenu.description}
                        price={elMenu.price}
                        imageUrl={elMenu.image_url}
                      /> :
                      <MenuCard
                        onAdd={() => getMenuItemByInd(elMenu.id)}
                        name={elMenu.name}
                        description={elMenu.description}
                        price={elMenu.price}
                        imageUrl={elMenu.image_url}
                      />
                    }
                  </Col>
                ))}
              </Row>
            </div>
          );
        }) }
      </div>
      { (isMobile && menuItemsCart && menuItemsCart.length) &&
        <div className="mobile__fixed_bottom__wrapper">
          <Button
            disabled={ !Boolean(menuItemsCart.length) || disableCartBtn }
            type="primary" size="large"
            onClick={ () => handleShowCart(true) }
            className="action__button app__btn" block
          >
            <span className="coco__icon bucket action_btn__icon"/>
            <span className="mr-1 ml-1">{ t('app.cart.title-mobile') } </span>
            <span>{ ` ${ menuItemsCart ? menuItemsCart.length : 0 }` }</span>
          </Button>
        </div> }
    </div>
  );
};

export default TheRestaurantMenu;

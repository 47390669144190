import ApiService from '../../services/api';
import TokenService from '../../services/storage/token';
import BaseRepositoryError from './base.errors';

const AuthRepository = {
  /*
   * Login the user and store the access token to TokenService.
   * @returns access_token
   * @throws BaseRepositoryError
  */
  login: async (payload) => {
    const requestData = {
      method: 'post',
      url: '/auth/login',
      data: {
        email: payload.email,
        password: payload.password,
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      
      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.access_token);
      ApiService.setAuthHeader();
      // TODO FIGURE AOUTH WITH interseptor
      ApiService.mount401Interceptor();
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  loginOauth: async (payload) => {
    const { provider, familyName, givenName, googleId, email } = payload;
    const requestData = {
      method: 'post',
      url: `/auth/oauth/${provider}`,
      data: {
        user: {
          first_name: givenName,
          last_name: familyName,
          gid: googleId,
          email,
        },
      },
    };

    try {
      const response = await ApiService.customRequest(requestData);
      
      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.access_token);
      ApiService.setAuthHeader();
      // TODO FIGURE AOUTH WITH interseptor
      // ApiService.mount401Interceptor();
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  register: async (payload) => {
    const requestData = {
      method: 'post',
      url: '/auth/register',
      data: { ...payload },
    };
    
    try {
      const response = await ApiService.customRequest(requestData);
      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.access_token);
      ApiService.setAuthHeader();
      // NOTE: We haven't covered this yet in our ApiService
      //       but don't worry about this just yet - I'll come back to it later
      ApiService.mount401Interceptor();
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  /**
   * Refresh the access token.
   * */
  refreshToken: async () => {
    const refreshToken = TokenService.getRefreshToken();
    const requestData = {
      method: 'post',
      url: '/api-token-refresh/',
      data: {
        refreshToken,
      },
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);

      TokenService.saveToken(response.data.access_token);
      TokenService.saveRefreshToken(response.data.refresh_token);
      // Update the header in ApiService
      ApiService.setAuthHeader();
      ApiService.mount401Interceptor();

      return response.data.token;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
  
  async resetPassword(payload) {
    const requestData = {
      method: 'post',
      url: 'auth/reset-password',
      data: {
        email: payload.email,
      },
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
  
  /**
   * Logout the current user by removing the token from storage.
   *
   * Will also remove `Authorization Bearer <token>` header from future requests.
   * */
  logout() {
    // Remove the token and remove Authorization header from Api Service as well
    TokenService.removeToken();
    TokenService.removeRefreshToken();
    ApiService.removeHeader();
    // NOTE: Again, we'll cover the 401 Interceptor a bit later.
    ApiService.unmount401Interceptor();
  },

};

export default AuthRepository;

export { AuthRepository, BaseRepositoryError };

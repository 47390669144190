import useScript from './script_loader';
import ParseAutoplacedAddress from './ParseAutoplacedAddress';
import ParseAutoplacedAddressLine from './ParseAutoplacedAddressLine';

export { useScript };

// ---- formatters
export const priceFormatter = (value) => (`$${value ? (value / 1e2).toFixed(2) : '0.00'}`);

export const truncateText = (value, count) => {
  if (value && value.length > count) {
    return `${value.substring(0, count)} ...`;
  }
  return value || '';
};

export const timeFormatter = (value) => (
  value.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
);

export const secondsToStringTime = (durationValue) => {
  if (!durationValue) {
    return '';
  }
  const hours = Math.floor(durationValue / 3600);
  const minutes = Math.floor(durationValue / 60);
  const hoursString = hours ? `${hours} ${hours ? (hours > 1 ? 'h' : 'h') : ''}` : '';
  const minutesString = hours ? `${minutes} min` : '';
  return `${hoursString} ${minutesString}`;
};
// ----

export { ParseAutoplacedAddress, ParseAutoplacedAddressLine };
import { put, takeLatest, call, select } from "redux-saga/effects";
import { push } from 'connected-react-router';
import { message } from 'antd';
import BaseRepositoryError from '../../repositories/base.errors';
import UserRepository from '../../repositories/user';
import { getAuthSuccessRouter } from '../../selectors/routers';

import {
  FETCH_ADD_PHONE_SAGA,
  FETCH_DATA_404,
  SET_DATA_ERROR_MSG
} from '../../actions/actionTypes';
import ROUTES from '../../../routes';

function* workerFetchAddPhone(action) {
  const userData = yield select(state => state.user.userData);
  const { phone, user_id } = action.payload;
  try {
    const id = user_id ? user_id : userData.id;
    const response = yield call(UserRepository.addPhone, id, { phone });
    yield call(message.success(response.message));
    const authSuccessRouter = yield select(getAuthSuccessRouter);
    yield put(push(
      { pathname: `${ROUTES.AUTH.path}/${ROUTES.AUTH.child.ADD_PHONE_VERIFY.path}`, state: { authSuccessRouter } }
    ));
  } catch (e) {
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* FetchAddPhone() {
  yield takeLatest(FETCH_ADD_PHONE_SAGA, workerFetchAddPhone);
}

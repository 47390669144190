import logoImg from '../../assets/logo.png';
import LogoBox from './LogoBox';
import ROUTES from '../../routes';
import { Link } from 'react-router-dom';

const AuthHeader = (props) => {
  const { restaurantSlug } = props;

  return (
    <div className="auth_header">
      <div className="auth_header_wrapper">
        <Link to={{ pathname: `${ROUTES.ORDER.link_path(restaurantSlug)}/${ROUTES.ORDER.child.MENU.path}`}}>
          <LogoBox logoImg={ logoImg }/>
        </Link>
      </div>
    </div>
  );
};

export default AuthHeader;
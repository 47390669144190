export const COST_FEE = 195;

// actions
export const CRUD_ACTIONS = {
  CLEAR: 'clear',
  DELETE: 'delete',
  CREATE: 'create',
  PUT: 'put',
};

export const ZENDESK_URL = process.env.REACT_APP_ZENDESK_URL || 'https://www.zendesk.com/';
export const CONTACT_EMAIL = process.env.REACT_APP_CONTACT_EMAIL || 'community@cyanbot.com';

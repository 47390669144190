import { put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
  FETCH_DATA_404,
  SET_DATA_ERROR_MSG,
  FETCH_DATA_ERROR_MSG,
} from '../actions/actionTypes';
import ROUTES from '../../routes';

const delay = time => new Promise(resolve => setTimeout(resolve, time));

function* handleErrorMsg(action) {
  yield put({type: SET_DATA_ERROR_MSG, payload: action.payload});
  yield call(delay, 1000);
  yield put({type: SET_DATA_ERROR_MSG, payload: null});
}

function* handle404() {
  yield put(push(ROUTES.NOT_FOUND.path));
}

export default function* watchErrorSaga() {
  yield takeLatest(FETCH_DATA_404, handle404);
  yield takeEvery(FETCH_DATA_ERROR_MSG, handleErrorMsg);
}
import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Spin, Row, Col, Select } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AuthGoogleBtn from './components/AuthGoogleBtn';
import ProfileForm from '../../components/Form/ProfileForm';

import {
  FETCH_LOGOUT_SAGA,
  FETCH_REGISTER_SAGA,
} from '../../redux/actions/actionTypes';

const SignUp = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formLayout, setFormLayout] = useState('vertical');
  const [loading, setLoading] = useState(false);
  const { errorMsg } = useSelector(state => state.errors);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  const formItemLayout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 14,
    },
  };
  
  useEffect(() => {
    dispatch({ type: FETCH_LOGOUT_SAGA });
  }, []);
  
  useEffect(() => {
    if (errorMsg) {
      setLoading(false);
    }
  }, [errorMsg]);

  const fetchSignUp = useCallback(
    (payload) => dispatch({ type: FETCH_REGISTER_SAGA,  payload }),
    [dispatch],
  );
  
  const handleFinish = (values) => {
    setLoading(true);
    fetchSignUp(values);
  };
  
  const fetchOauthLogin = useCallback(
    (payload) => dispatch({ type: FETCH_LOGIN_OAUTH_SAGA,  payload }),
    [dispatch],
  );

  return(
    <div>
      <Spin spinning={loading} indicator={antIcon}>
        <div className="auth_card__wrap mb-2">
          <AuthGoogleBtn onLoginOauth={fetchOauthLogin}/>
        </div>
        <div className="auth_card__wrap">
          <ProfileForm
            formLayout={formLayout}
            onFinish={handleFinish}
            userInitialData={{ phoneCode: '+1' }}
            btnText={ t('auth.sign_up') }
            isPassword={true}
          />
        </div>
      </Spin>
    </div>
  );
};

export default SignUp;
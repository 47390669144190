import { Row, Col } from 'antd';
import './LandingFooter.less';
import LogoImgSrc from '../../assets/logo.png';
import GooglePlaySrc from '../../assets/google-play.png';
import AppStoreSrc from '../../assets/app_store.png';

const LandingFooter = () => {
  return (
    <div className='footer__wrapper'>
      <Row>
        <Col md={12} xs={24}>
          <div className="logo__wrapper">
            <img src={LogoImgSrc} alt="COCO"/>
          </div>
          <div className="app__wrapper">
            <a href="https://apps.apple.com/us/app/coco-robot-delivery/id1532572102">
              <img src={AppStoreSrc} alt="COCO"/>
            </a>
            <a>
              <img src={GooglePlaySrc} alt="COCO"/>
            </a>
          </div>
          <div className="icon__wrapper">
            <a href="https://www.facebook.com/CocoRobotics/" target="_blank">
              <span className="coco__icon footer__icon facebook">
              </span>
            </a>
            <a href="#" target="_blank">
              <span className="coco__icon footer__icon twitter">
              </span>
            </a>
            <a href="https://www.instagram.com/coco.robot/" target="_blank">
              <span className="coco__icon footer__icon instagram">
              </span>
            </a>
          </div>
        </Col>
        <Col md={{'span': 7, 'offset': 5 }} xs={24} >
          <Row className="mt-1">
            <Col span={8}>
              <div className="links__wrapper">
                <span className="link__header">
                  Company
                </span>
                <a href="https://jobs.wrkhq.com/cyanbot" title="Careers">
                  Careers
                </a>
                <a href="https://www.cocodelivery.com/" title="About us">
                  About us
                </a>
              </div>
            </Col>
            <Col span={8}>
              <div className="links__wrapper">
                <span className="link__header">
                  Work with us
                </span>
                <a href="mailto:pilotjobs@cyanbot.com?subject=I'd%20like%20to%20be%20a%20pilot!">
                  Drive our robots
                </a>
                <a href="https://www.cocodelivery.com/partners" target="_blank">
                  Join the Platform
                </a>
              </div>
            </Col>
            <Col span={8}>
              <div className="links__wrapper">
                <span className="link__header">
                  Support
                </span>
                <a href="mailto:community@cyanbot.com">
                  Contact Us
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12} xs={24} className="policy_link">
          <span>
            © Copyright Cyan Robotics Inc.
          </span>
        </Col>
        <Col md={{'span': 3, 'offset': 6}} xs={24} className="policy_link">
          <a href="https://www.cocodelivery.com/privacy" target="_blank"> Privacy </a>
        </Col>
        <Col md={{'span': 3 }} xs={24} className="policy_link">
          <a href="https://www.cocodelivery.com/terms" target="_blank"> Terms & Conditions </a>
        </Col>
      </Row>
    </div>
  );
};

export default LandingFooter;

import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Dropdown, Menu, Button, Popover } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import LocationWrapper from "../LocationWrapper";
import LogoBox from "../LogoBox.jsx";

import logoImg from "../../../assets/logo.png";
import ROUTES from "../../../routes";

const DesktopHeader = (props) => {
  const {
    showCart,
    isShowCart,
    refOutsideClick,
    menuItemsCart,
    fetchLogout,
    isAuthenticated,
    signInPath,
    signUpPath,
    authSuccessRouter,
    isShowCartBtn,
    restaurantSlug,
    fetchGenAddress,
    disableCartBtn,
    basic,
    noBorder,
  } = props;
  const { address1, address2 } = props.genAddress;
  const { t } = useTranslation();

  const menu = (
    <Menu>
      <Menu.Item>
        <Link
          to={{
            pathname: `${ROUTES.PROFILE.path}/${ROUTES.PROFILE.child.UPDATE.path}`,
          }}
        >
          {t("profile.menu-list.update-profile")}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <a onClick={() => fetchLogout()}>{t("profile.menu-list.logout")}</a>
      </Menu.Item>
    </Menu>
  );

  return (
    <header className="app_header">
      <div className={`app_header__wrapper${noBorder ? " no-border" : ""}`}>
        <div className="app_header__wrap">
          <Link
            to={{
              pathname: `${ROUTES.ORDER.link_path(restaurantSlug)}/${
                ROUTES.ORDER.child.MENU.path
              }`,
            }}
          >
            <LogoBox logoImg={logoImg} />
          </Link>
          {!basic && (
            <>
              <div className="app_header__delivery_choice">
                <a className="underline__link active">
                  {t("app.header.delivery")}
                </a>
                {/*
                <span className="or_text">{ t('app.header.or') }</span>
                <a className="underline__link">{ t('app.header.pick_up') }</a>
                */}
              </div>
              {isShowCartBtn && (
                <LocationWrapper
                  className={"app_header__location"}
                  fetchGenAddress={fetchGenAddress}
                  address={`${address1}, ${address2}`}
                  isMobile={false}
                />
              )}
            </>
          )}
        </div>
        {!basic && (
          <div className="app_header__wrap">
            <div className="app_header__auth">
              {isAuthenticated ? (
                <Dropdown overlay={menu}>
                  <Avatar
                    className="user_header__icon"
                    icon={<UserOutlined />}
                  />
                </Dropdown>
              ) : (
                <>
                  <Link
                    className="primary__link"
                    to={{ pathname: signInPath, state: { authSuccessRouter } }}
                  >
                    {t("auth.log_in")}
                  </Link>
                  <Link
                    className="primary__link"
                    to={{ pathname: signUpPath, state: { authSuccessRouter } }}
                  >
                    {t("auth.sign_up")}
                  </Link>
                </>
              )}
            </div>
            {isShowCartBtn && (
              <div className="app_header__bucket" ref={refOutsideClick}>
                <Button
                  type="primary"
                  size="large"
                  className="action__button app__btn"
                  onClick={() => showCart(!isShowCart)}
                >
                  <span className="coco__icon bucket action_btn__icon" />
                  <span>{t("app.cart.title")}</span>
                  <span>{menuItemsCart ? menuItemsCart.length : 0}</span>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </header>
  );
};

export default DesktopHeader;

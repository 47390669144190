import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CRUD_ACTIONS } from '../../../../const';
import DesktopModalAddItem from './DesktopModalAddItem';
import MobileModalAddItem from './MobileModalAddItem';
import { RestaurantMenuContext } from '../../TheRestaurantMenu/context';


const ModalAddItem = (props) => {
  const DELETE_ITEM = 'No Thanks!';
  const { isModalVisible, onClose, openedMenuItem, addToCart, isMobile } = props;
  const [addOns, setAddOns] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [totalPrice, setTotalPrice] = useState(0);
  const [instruction, setInstruction] = useState('');

  useEffect(() => {
    setDefaultState();
    defaultObjects();
    setTotalPrice(openedMenuItem.price);
  }, [isModalVisible]);

  useEffect(() => {
    const additionalPrices = Object.entries(addOns).reduce((newPrice, [key, val]) => {
      return newPrice + val.added_price;
    }, 0);
    setTotalPrice((additionalPrices + openedMenuItem.price) * quantity);
  }, [quantity, addOns]);

  const defaultObjects = () => {
    // set defaults data for select with added_price 0
    if (openedMenuItem.menu_options && openedMenuItem.menu_options.length) {
      let defaultAddOns = {};
      openedMenuItem.menu_options.forEach(opt => {
        // set select
        if (opt.children && opt.children.length) {
          let zeroPrice = opt.children.find(el => el.added_price === 0);
          if (zeroPrice) {
            defaultAddOns[opt.name] = { name: zeroPrice.name, added_price: zeroPrice.added_price};
          }
        }
        if (opt.default_value) {
          defaultAddOns[opt.name] = { name: opt.name, added_price: opt.added_price};
        }
      });
      setAddOns(defaultAddOns);
    }
  };

  const setDefaultState = () => {
    setAddOns({});
    setQuantity(1);
    setTotalPrice(0);
    setInstruction('');
  };

  const increaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decreaseQuantity = () => {
    if (quantity - 1 > 0) {
      setQuantity(quantity - 1);
    }
  };

  const updateInstruction = ({ target: { value } }) => {
    setInstruction(value);
  };

  const updateAddOns = (updatedItem) => {
    setAddOns({ ...addOns, ...updatedItem });
  };

  const deleteFromAddOns = (name) => {
    const newAddOns = { ...addOns };
    delete newAddOns[name];
    setAddOns({ ...newAddOns });
  };

  const handleSelect = (name, value) => {
    const [valueName, added_price] = value;
    if (valueName === DELETE_ITEM) {
      deleteFromAddOns(name);
    } else {
      updateAddOns({[name]: { name: valueName, added_price}});
    }
  };

  const handleCheckBox = (option) => {
    if (addOns[option.name]) {
      deleteFromAddOns(option.name);
    } else {
      updateAddOns({[option.name]: { name: option.name, added_price: option.added_price }});
    }
  };

  const handleClose = () => {
    setDefaultState();
    onClose();
  };

  const handleAddToCart = () => {
    addToCart({
      payload: {
        quantity,
        addOns,
        totalPrice,
        instruction,
        ...openedMenuItem,
      },
      action: CRUD_ACTIONS.PUT,
    });
    handleClose();
  };

  return (
    <RestaurantMenuContext.Provider
      value={{
        addOns, quantity, decreaseQuantity, increaseQuantity,
        handleCheckBox, handleSelect, DELETE_ITEM,
      }}
    >
      { isMobile ?
        <MobileModalAddItem
          isModalVisible={isModalVisible}
          instruction={instruction}
          updateInstruction={updateInstruction}
          quantity={quantity}
          openedMenuItem={openedMenuItem}
          totalPrice={totalPrice}
          handleClose={handleClose}
          handleAddToCart={handleAddToCart}
        /> :
        <DesktopModalAddItem
          isModalVisible={isModalVisible}
          instruction={instruction}
          updateInstruction={updateInstruction}
          quantity={quantity}
          openedMenuItem={openedMenuItem}
          totalPrice={totalPrice}
          handleClose={handleClose}
          handleAddToCart={handleAddToCart}
        />
      }
    </RestaurantMenuContext.Provider>
  );
};

export default ModalAddItem;

import { put, takeLatest, call, select } from "redux-saga/effects";
import BaseRepositoryError from '../../repositories/base.errors';
import UserRepository from '../../repositories/user';

import {
  FETCH_UPDATE_USER_DATA_SAGA,
  FETCH_DATA_404,
  SET_USER_DATA,
  SET_DATA_ERROR_MSG,
  FETCH_GEN_ADDRESS_SAGA,
} from '../../actions/actionTypes';


function* workerUserUpdateData(action) {
  const { phone, first_name, last_name, email, suite, addressObj, user_id } = action.payload;
  try {
    // set delivery address  if it's empty
    const genAddress = yield select(state => state.delivery.genAddress);
    if (!genAddress.address1) {
      yield put ({ type: FETCH_GEN_ADDRESS_SAGA, payload: addressObj });
    }
    const requestPayload = {
      first_name,
      last_name,
      email,
      phone,
      address: {
        address1: addressObj.address1,
        address2: addressObj.address2,
        suite,
        location: {
          lat: addressObj.lat,
          lng: addressObj.lng,
        },
      },
    };
    const userData = yield select(state => state.user.userData);
    let updatedUserData = yield call(UserRepository.updateUser, user_id || userData.id, requestPayload);
    if (updatedUserData.phone !== userData.phone) {
      updatedUserData.phone_verified = false;
    }
    yield put ({ type: SET_USER_DATA, payload: updatedUserData });
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
    }
  }
}

export default function* watchUserUpdateData() {
  yield takeLatest(FETCH_UPDATE_USER_DATA_SAGA, workerUserUpdateData);
}

import './CardMenuItem.less';
import { priceFormatter, truncateText } from '../../utils';

const CardMenuItem = (props) => {
  const { ind,  confirmDelete, menuItem } = props;
  const { name, description, price, quantity, id, addOns } = menuItem;
  // const getPrice

  return (
    <div className="menu_item_card__wrapper">
      <div className="card__wrap">
        <span className="app_text_medium_bg">
          { quantity }
        </span>
      </div>
      <div className="card__wrap pt-0-25">
        <h3 className="semi-bold">
          { truncateText(name, 50) }
        </h3>
        <p className="card_info__decs app_medium_color">
          { truncateText(description, 60) }
        </p>
        <p className="semi-bold color_app__primary">
          { priceFormatter(price) }
        </p>
      </div>
      <div className="card__wrap">
        <a onClick={() => confirmDelete([id, ind])}>
          <span className="color_app__primary_hover coco__icon close sm"/>
        </a>
      </div>
    </div>
  );
};

export default CardMenuItem;

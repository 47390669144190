import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Spin, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import AuthGoogleBtn from './components/AuthGoogleBtn';
import { Link } from 'react-router-dom';
import ROUTES from '../../routes';

import {
  FETCH_LOGIN_SAGA,
  FETCH_LOGIN_OAUTH_SAGA,
  FETCH_LOGOUT_SAGA,
} from '../../redux/actions/actionTypes';

const SignIn = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formLayout, setFormLayout] = useState('vertical');
  const [loading, setLoading] = useState(false);
  const { errorMsg } = useSelector(state => state.errors);
  
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  
  useEffect(() => {
    dispatch({ type: FETCH_LOGOUT_SAGA });
  }, []);
  
  useEffect(() => {
    if (errorMsg) {
      setLoading(false);
    }
  }, [errorMsg]);

  const fetchLogin = useCallback(
    (payload) => dispatch({ type: FETCH_LOGIN_SAGA,  payload }),
    [dispatch],
  );
  
  const fetchOauthLogin = useCallback(
    (payload) => dispatch({ type: FETCH_LOGIN_OAUTH_SAGA,  payload }),
    [dispatch],
  );

  const onFinish = (values) => {
    fetchLogin(values);
    setLoading(true);
  };

  return(
    <div>
      <Spin spinning={loading} indicator={antIcon}>
      <div className="auth_card__wrap mb-2">
        <AuthGoogleBtn onLoginOauth={fetchOauthLogin}/>
      </div>
      <div className="auth_card__wrap">
        <Form
          className="auth__form"
          layout={formLayout}
          size={'large'}
          initialValues={{ layout: formLayout }}
          onFinish={onFinish}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={ [
              { required: true, message: t('form.warning.email-required') },
              { type: 'email', message: t('form.warning.email-type') },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please input your password!' }]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <Button
              type="primary"
              size="large"
              htmlType="submit"
              className="auth_btn app__btn text-upper" block
            >
              { t('auth.sign_in') }
            </Button>
          </Form.Item>
        </Form>
      </div>
      <div className="mt-1 text-center">
        <Link
          className="primary__link underline__link"
          to={ { pathname: `${ROUTES.AUTH.path}/${ROUTES.AUTH.child.RESET_PASSWORD.path}` } }
        >
          { t('auth.reset-password') }
        </Link>
      </div>
      </Spin>
    </div>
  );
};

export default SignIn;
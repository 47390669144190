import { priceFormatter, truncateText } from '../../../../utils';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

const CartTable = (props) => {
  const { confirmDelete, menuItemsCart, openedSublist, updateOpenedSublist } = props;
  
  const renderMainRow = (menuItem, ind) => {
    return (
      <div className="cart__table_row_item_wrapper">
        <div className="cart__table_row_item">
          <span className="mr-1">{ menuItem.quantity }</span>
          <a
            className={Object.entries(menuItem.addOns).length && 'hover__app_color'}
            onClick={() => updateOpenedSublist(`${menuItem.id}${ind}`)}
          >
            { Object.entries(menuItem.addOns).length
              ? <>{openedSublist[`${menuItem.id}${ind}`] ? <CaretDownOutlined/> : <CaretUpOutlined/>}</>
              : '' }
            <span className="ml-0-25">
              { truncateText(menuItem.name, 50) }
            </span>
          </a>
        </div>
        <div className="cart__table_row_item">
          <span className="color_app__primary mr-2">
            {priceFormatter(menuItem.totalPrice)}
          </span>
          <a onClick={() => confirmDelete([menuItem.id, ind])}>
            <span className="color_app__primary_hover coco__icon close sm"/>
          </a>
        </div>
      </div>
    );
  };
  
  const renderSublist = (addOnsList) => {
    return (
      <ul className="cart__table_subitem_wrap">
        {addOnsList.map(([key, val], ind) => (
          <li key={`subItem_${ind}`}>
            <span>{truncateText(val.name, 50)}</span>
            <span className="color_app__primary">{priceFormatter(val.added_price)}</span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="cart__table_wrapper app__scrolled">
      { menuItemsCart.map((menuItem, ind) => (
        <div className="cart__table_row" key={`row_${ind}`}>
          { renderMainRow(menuItem, ind) }
          { Object.entries(menuItem.addOns).length ?
            <div className="cart__table_row_subitems_wrapper">
              {
                openedSublist[`${menuItem.id}${ind}`] &&
                renderSublist(Object.entries(menuItem.addOns))
              }
            </div>
            : null }
        </div>
      ))}
    </div>
  );
};

export default CartTable;
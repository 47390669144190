import ApiService from '../../services/api';
import TokenService from '../../services/storage/token';
import BaseRepositoryError from './base.errors';

const UserRepository = {
  /*
   * Login the user and store the access token to TokenService.
   * @returns access_token
   * @throws BaseRepositoryError
  */
  fetchUser: async (id, payload) => {
    const requestData = {
      method: 'get',
      url: `/users/${id}`,
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      console.debug('fetchUser error', error);
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  updateUser: async (id, payload) => {
    const requestData = {
      method: 'put',
      url: `/users/${id}`,
      data: { ...payload },
    };

    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      console.debug('updateUser error', error);
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  addPhone: async (id, payload) => {
    const requestData = {
      method: 'post',
      url: `/users/${id}/phone_add`,
      data: {
        phone: payload.phone,
      },
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      console.debug('addPhone error', error);
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },

  addPhoneCodeVerify: async (id, payload) => {
    const requestData = {
      method: 'post',
      url: `/users/${id}/phone_verify`,
      data: {
        phone_verification_code: payload.code,
      },
    };
    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      console.debug('addPhoneCodeVerify error', error);
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default UserRepository;

export { UserRepository, BaseRepositoryError };

import { combineReducers } from 'redux';

import { persistReducer } from 'redux-persist';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';

// modules
import restaurantReducer, { restaurantInitialState } from './restaurant';
import delivery from './delivery';
import orderData from './order';
import setErrors from './errors';
import setUserData from './users';
import rootData from './root';
import cartData from './cart';
import orderPlacedData from './order_placed';
import paymentsData from './payments';
import optionsData from './options';
import restrictedData from './restricted';


const deliveryPersistConfig = {
  key: 'delivery',
  storage,
  blacklist: ['isInTimeToDelivery', 'serviceInfo',],
};


const createAppReducer = (history) => combineReducers({
  router: connectRouter(history),
  root: rootData,
  user: setUserData,
  options: optionsData,
  restaurant: restaurantReducer,
  delivery:  persistReducer(deliveryPersistConfig, delivery),
  order: orderData,
  errors: setErrors,
  cart: cartData,
  orderPlaced: orderPlacedData,
  payments: paymentsData,
  restrictedCheckout: restrictedData
});

export default createAppReducer;

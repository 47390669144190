import React from 'react';
import ReactDOM from 'react-dom';

import App from './App.jsx';
import reportWebVitals from './reportWebVitals';

import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import store, { history, persistor } from './redux/store';
import ApiService from './services/api';
import FirebaseService from './services/firebase';
import { PersistGate } from 'redux-persist/integration/react';

import { apiHosts } from './configs';

ApiService.init(apiHosts.baseHost);
FirebaseService.init();

// locales
import en_US from 'antd/es/locale/en_US';
import i18n from './configs/i18n';


ReactDOM.render(
  <ConfigProvider locale={en_US}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <App/>
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </ConfigProvider>,
  document.getElementById('root')
);

import { I18nextProvider } from 'react-i18next';

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

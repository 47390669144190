import { useEffect, useState } from "react";
import Picker from "react-mobile-picker";

const DatePicker = ({ onChange, value }) => {
  const [valueGroups, setValueGroups] = useState({
    month: value ? value.toLocaleString("default", { month: "short" }) : "Jul",
    day: value ? `${value.getDate()}` : "15",
    year: value
      ? `${value.toLocaleString("default", { year: "numeric" })}`
      : "2000",
  });

  const [optionGroups] = useState({
    month: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    day: Array.from({ length: 31 }, (_, i) => `${i + 1}`),
    year: Array.from({ length: 121 }, (_, i) => `${i + 1900}`),
  });

  useEffect(() => {
    const noScrollClass = "no-overflow";
    const elements = ["html", "body"];
    elements.forEach((el) =>
      document.querySelector(el).classList.add(noScrollClass)
    );

    return () =>
      elements.forEach((el) =>
        document.querySelector(el).classList.remove(noScrollClass)
      );
  }, []);

  useEffect(() => {
    if (valueGroups) {
      const { day, month, year } = valueGroups;
      const dob = new Date(Date.parse(`${month} ${day} ${year}`));

      if (+dob !== +value) {
        onChange(dob);
      }
    }
  }, [valueGroups]);

  return (
    <Picker
      height={236} // Standard but allowing for a 56px tall selected item
      onChange={(name, value) =>
        setValueGroups(() => ({ ...valueGroups, [name]: value }))
      }
      optionGroups={optionGroups}
      valueGroups={valueGroups}
    />
  );
};

export default DatePicker;

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Input, Button } from 'antd';

import Counter from '../../../../components/Form/Counter';
import Options from './Options';

import { priceFormatter } from '../../../../utils';
import { RestaurantMenuContext  } from '../../TheRestaurantMenu/context';

const { TextArea } = Input;

const DesktopModalAddItem = (props) => {
  const { t } = useTranslation();
  const { quantity, increaseQuantity, decreaseQuantity, addOns } = useContext(RestaurantMenuContext);
  const { isModalVisible, instruction, updateInstruction, openedMenuItem, totalPrice } = props;
  // methods
  const { handleClose, handleAddToCart } = props;

  const renderFooter = () => {
    return (
      <div className="modal__footer">
        <div className="count__wrapper">
          <Counter
            quantity={quantity}
            decrease={decreaseQuantity}
            increase={increaseQuantity}
          />
        </div>
        <div className="checkout__wrapper">
          <Button onClick={ handleAddToCart } type="primary" className="action__button search">
            <span>{ t('app.add-menu-item.add-to-cart') }</span>
            <span>{priceFormatter(totalPrice)}</span>
          </Button>
        </div>
      </div>
    );
  };

  return (
    <Modal
      visible={ isModalVisible }
      onCancel={ handleClose }
      title={ <h3 className="text-1-375x semi-bold" >{openedMenuItem.name}</h3> }
      width={650}
      className="modal-menu modal_add_product"
      footer={renderFooter()}
    >
      <p className="app_medium_color mb-1 text-1x">{ openedMenuItem.description }</p>
      {
        openedMenuItem.menu_options &&
        <div className="extra__add mb-1">
          <h4 className="mb-1 semi-bold text-sm"> { t('app.add-modal.add-extra') } </h4>
          <div className="menu_option__wrapper app__scrolled">
            <Options
              menuOptions={openedMenuItem.menu_options}
              addOns={addOns}
            />
          </div>
        </div>
      }
      <div>
        <p className="color_app__primary text-sm mb-1">
          { t('app.add-menu-item.add-instructions') }
        </p>
        <TextArea
          value={ instruction }
          onChange={ updateInstruction }
          className="non___border text-1x mb-1"
          placeholder={ t('app.add-menu-item.add-instructions-placeholder') }
          autoSize={{ minRows: 2, maxRows: 5 }}
        />
      </div>
    </Modal>
  );
};

export default DesktopModalAddItem;

import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button } from "antd";
import { useMediaQuery } from "react-responsive";
import { useDispatch, useSelector } from "react-redux";

import { CRUD_ACTIONS } from "../../const";
import { priceFormatter, timeFormatter } from "../../utils";
import {
  FETCH_ORDER_CART_TOTAL_SAGA,
  FETCH_ORDER_DATA_SAGA,
  UPDATE_MENU_ITEMS_CART_SAGA,
} from "../../redux/actions/actionTypes";
import chatImg from "../../styles/icons/chat.svg";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ZENDESK_URL, CONTACT_EMAIL } from "../../const";
import { Helmet } from "react-helmet";

const ThankYou = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ query: "(max-width: 758px)" });

  const [cartTotal, setCartTotal] = useState({});
  const { restaurantData } = useSelector((state) => state.restaurant);
  const { orderPlacedData } = useSelector((state) => state.orderPlaced);

  const getDeliveryTime = () => {
    const eta = new Date(
      orderPlacedData.created_at + orderPlacedData.given_eta * 60
    );
    return timeFormatter(eta);
  };

  const getOrderDate = () => {
    const orderDate = new Date(orderPlacedData.created_at);
    return timeFormatter(orderDate);
  };

  const getAddress = () => {
    if (orderPlacedData.destination) {
      return `${orderPlacedData.destination.address1}`;
    }
    return "";
  };

  const clearMenuItemOrder = useCallback(
    () =>
      dispatch({
        type: UPDATE_MENU_ITEMS_CART_SAGA,
        payload: { action: CRUD_ACTIONS.CLEAR },
      }),
    [dispatch]
  );

  useEffect(() => {
    dispatch({ type: FETCH_ORDER_DATA_SAGA, payload: orderId });
    clearMenuItemOrder();
  }, []);

  useEffect(() => {
    if (orderPlacedData) {
      const {
        delivery_fee,
        final_price,
        sales_tax,
        subtotal_price,
        total_price,
      } = orderPlacedData;
      setCartTotal({
        delivery_fee,
        final_price,
        sales_tax,
        subtotal_price,
        total_price,
      });
    }
  }, [orderPlacedData]);

  return (
    <div className="thank__you">
      <Helmet>
        <title>Thank you</title>
      </Helmet>
      <div className="summary_box">
        <div className="summary_wrapper">
          <h1>{t("app.thanks.header")}</h1>
          <p className="head__text">{t("app.thanks.sub-header")}</p>
        </div>
      </div>
      <div className="information__box">
        <p className="head__text">
          <span>{t("app.thanks.info-text-before-address")}</span>
          <span className="ml-0-25 color_app__primary"> {getAddress()} </span>
          <span className="ml-0-25">{t("app.thanks.info-text-at-time")}</span>
          <span className="ml-0-25 color_app__primary">
            {" "}
            {getDeliveryTime()}{" "}
          </span>
          <span className="ml-0-25">
            {t("app.thanks.info-text-after-time")}
          </span>
        </p>
      </div>
      <div className="restaurant_data__wrapper">
        <Row className="header__wrap">
          <Col xs={24} md={20}>
            <div className="menu__name_wrap">
              <h1 className="text-1-5x">{restaurantData.name}</h1>
            </div>
            <div className="menu__delivery_data_wrap text-sm">
              <span className="app__text-underline">ORDER #{orderId}</span>
            </div>
          </Col>
          {!isMobile && (
            <Col xs={24} md={4} className="restaurant__logo__wrapper">
              {restaurantData.logo_img && (
                <img src={restaurantData.logo_img} alt="COCO" />
              )}
            </Col>
          )}
        </Row>
        <Row className="detail__wrapper">
          <Col xs={12} md={9}>
            <div className="detail__item">
              <span>{t("app.thanks.delivery-status")}</span>
              <span>
                <span className="coco__icon elipse ssm mr-0-25 color_app_success__icon" />
                <span className="app_success_color">In Progress</span>
              </span>
            </div>
          </Col>
          <Col xs={12} md={9}>
            <div className="detail__item">
              <span>{t("app.thanks.phone")}</span>
              <span>
                <span className="app_medium_color">
                  {orderPlacedData.customer_phone}
                </span>
              </span>
            </div>
          </Col>
        </Row>
        <Row className="detail__wrapper">
          <Col xs={12} md={9}>
            <div className="detail__item">
              <span>{t("app.thanks.order-date")}</span>
              <span className="app_medium_color">{getOrderDate()}</span>
            </div>
          </Col>
        </Row>
        <Row className="mt-1">
          <div className="cart_subtotal__wrapper">
            <div className="cart_subtotal__wrap mb-1">
              <span className="semi-bold">{t("app.thanks.order-summary")}</span>
              {!!cartTotal.final_price && (
                <span className="semi-bold">
                  {`${t("app.thanks.total")}: ${priceFormatter(
                    cartTotal.final_price
                  )}`}
                </span>
              )}
            </div>
            {!!cartTotal.subtotal_price && (
              <div className="cart_subtotal__wrap">
                <span>{t("app.thanks.subtotal")}</span>
                <span className="app_medium_color">
                  {priceFormatter(cartTotal.subtotal_price)}
                </span>
              </div>
            )}
            {!!cartTotal.sales_tax && (
              <div className="cart_subtotal__wrap">
                <span>{t("app.thanks.sales-tax")}</span>
                <span className="app_medium_color">
                  {priceFormatter(cartTotal.sales_tax)}
                </span>
              </div>
            )}
            {!!cartTotal.delivery_fee && (
              <div className="cart_subtotal__wrap">
                <span>{t("app.thanks.delivery-fee")}</span>
                <span className="app_medium_color">
                  {priceFormatter(cartTotal.delivery_fee)}
                </span>
              </div>
            )}
            {cartTotal.discount && (
              <div className="cart_subtotal__wrap">
                <span>{t("app.thanks.discount")}</span>
                <span className="app_medium_color">
                  -{priceFormatter(cartTotal.discount)}
                </span>
              </div>
            )}
          </div>
        </Row>
        <Row className="mt-1">
          <div className="cart_menu__wrapper">
            {orderPlacedData.items &&
              orderPlacedData.items.map((el, ind) => (
                <div key={`menu_item${ind}`} className="cart_menu__wrap">
                  <span className="app_medium_color">{el.quantity}x</span>
                  <span>{el.name}</span>
                  {!!el.price && (
                    <span className="app_medium_color">
                      ${Math.floor(el.price / 100)}.
                      {("00" + (el.price % 100)).slice(-2)}
                    </span>
                  )}
                </div>
              ))}
          </div>
        </Row>
      </div>
      {/* <div className="contact__wrapper mt-1">
        <Row className="mt-1">
          <div className="need_help_wrapper">
            <a href={ZENDESK_URL} target="_blank">
              <img src={chatImg} alt={ t('app.thanks.need-help-title') } className="mb-1"/>
              <p className="mb-0-5">{ t('app.thanks.need-help-title') }</p>
              <p className="app_medium_color mb-1">{ t('app.thanks.need-help-text') }</p>
            </a>
          </div>
        </Row>
        <Row className="mt-3" justify="center">
          <a
            type="primary"
            size="large"
            href={`mailto:${CONTACT_EMAIL}`}
            className="auth_btn app__btn"
          >
            Contact us
          </a>
        </Row>
      </div> */}
    </div>
  );
};

export default ThankYou;

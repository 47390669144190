import { SET_MENU_ITEMS_CART } from '../../actions/actionTypes';

export const orderInitialState = {
  menuItemsCart: [],
};

export default function orderData(state = orderInitialState, action) {
  switch (action.type) {
    case SET_MENU_ITEMS_CART:
      return {
        ...state,
        menuItemsCart: action.payload,
      };
    default:
      return state;
  }
}
import {
  SET_IS_MOBILE,
} from '../actions/actionTypes';

const initialState = {
  isMobile: null,
};


export default function optionsData(state = initialState, action) {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
}
// root
export const SET_DATA_ERROR_MSG = "SET_DATA_ERROR_MSG";
export const SET_SLUG_RESTAURANT = "SET_SLUG_RESTAURANT";

// auth
export const FETCH_LOGIN_SAGA = "FETCH_LOGIN_SAGA";
export const FETCH_LOGIN_OAUTH_SAGA = "FETCH_LOGIN_OAUTH_SAGA";
export const FETCH_LOGOUT_SAGA = "FETCH_LOGOUT_SAGA";
export const FETCH_REGISTER_OAUTH_SAGA = "FETCH_REGISTER_OAUTH_SAGA";
export const FETCH_REGISTER_SAGA = "FETCH_REGISTER_SAGA";
export const FETCH_RESET_PASSWORD_SAGA = "FETCH_RESET_PASSWORD_SAGA";
export const SET_AUTH_ERROR = "SET_AUTH_ERROR";

// users
export const FETCH_ADD_PHONE_SAGA = "FETCH_ADD_PHONE_SAGA";
export const FETCH_USER_DATA_SAGA = "FETCH_USER_DATA_SAGA";
export const FETCH_UPDATE_USER_DATA_SAGA = "FETCH_UPDATE_USER_DATA_SAGA";
export const FETCH_ADD_PHONE_CODE_VERIFY_SAGA =
  "FETCH_ADD_PHONE_CODE_VERIFY_SAGA";
export const SET_USER_DATA = "SET_USER_DATA";

// results
export const FETCH_DATA_404 = "FETCH_DATA_404";
export const FETCH_DATA_ERROR_MSG = "FETCH_DATA_ERROR_MSG";

// restaurant
export const FETCH_RESTAURANT_DATA_SAGA = "FETCH_RESTAURANT_DATA_SAGA";
export const FETCH_RESTAURANT_DATA_SAGA_FINISH =
  "FETCH_RESTAURANT_DATA_SAGA_FINISH";
export const SET_RESTAURANT_DATA = "SET_RESTAURANT_DATA";
export const FETCH_RESTAURANT_MENU_DATA_SAGA =
  "FETCH_RESTAURANT_MENU_DATA_SAGA";
export const SET_RESTAURANT_MENU = "SET_RESTAURANT_MENU_DATA";
export const SET_RESTAURANT_MENU_CATEGORIES = "SET_RESTAURANT_MENU_CATEGORIES";

// delivery
export const FETCH_GEN_ADDRESS_SAGA = "FETCH_GEN_ADDRESS_SAGA";
export const FETCH_SERVICE_INFO_SAGA = "FETCH_SERVICE_INFO_SAGA";
export const SET_GEN_ADDRESS_IS_COVERAGE = "SET_GEN_ADDRESS_IS_COVERAGE";
export const SET_SERVICE_INFO_DATA = "SET_SERVICE_INFO_DATA";
export const SET_IS_IN_TIME_TO_DELIVERY = "SET_IS_CLOSE_TO_DELIVERY";
export const SET_GEN_ADDRESS_SUCCESS = "SET_GEN_ADDRESS_SUCCESS";
export const SET_GEN_ADDRESS_ERR = "SET_GEN_ADDRESS_ERR";

// order
export const UPDATE_MENU_ITEMS_CART_SAGA = "UPDATE_MENU_ITEMS_CART_SAGA";
export const FETCH_ORDER_CART_TOTAL_SAGA = "FETCH_ORDER_CART_TOTAL_SAGA";
export const FETCH_ORDER_CART_TOTAL_PROMO_SAGA =
  "FETCH_ORDER_CART_TOTAL_PROMO_SAGA";
export const FETCH_ORDER_PLACE_SAGA = "FETCH_ORDER_PLACE_SAGA";
export const FETCH_ORDER_DATA_SAGA = "FETCH_ORDER_DATA_SAGA";

export const SET_STRIPE_ERROR_MSG = "SET_STRIPE_ERROR_MSG";

export const SET_MENU_ITEMS_CART = "SET_MENU_ITEMS_CART";
export const SET_ORDER_CART_TOTAL = "SET_ORDER_CART_TOTAL";

export const SET_STRIPE_CLIENT_SECRET = "SET_STRIPE_CLIENT_SECRET";
export const SET_ORDER_PLACED_ID = "SET_ORDER_PLACED_ID";
export const SET_ORDER_PLACED_DATA = "SET_ORDER_PLACED_DATA";

// options
export const SET_IS_MOBILE = "SET_IS_MOBILE";

// restricted orders
export const FETCH_UPDATE_RESTRICTED_ORDER_DATA =
  "FETCH_UPDATE_RESTRICTED_ORDER_DATA";
export const SET_UPDATE_RESTRICTED_ORDER_DATA =
  "SET_UPDATE_RESTRICTED_ORDER_DATA";

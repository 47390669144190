import { Button, message } from 'antd';

import { useTranslation } from 'react-i18next';
import { OauthProviders } from '../../../configs';
import GoogleLogin from 'react-google-login';

const AuthGoogleBtn = (props) => {
  const { onLoginOauth } = props;
  const { t } = useTranslation();

  const responseGoogle = (response) => {
    console.log('responseGoogle', response);
    if (response && response.profileObj) {
      onLoginOauth({...response.profileObj, provider: OauthProviders.google.key });
    }
    if (response && response.error) {
      message.warning(`${response.error} ${response.details ? response.details : ''}`);
    }
  };

  return (
    <GoogleLogin
      clientId={ OauthProviders.google.uri }
      render={ renderProps => (
        <Button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          type="primary" size="large"
          className="auth_btn google__btn social__btn" block
        >
          <span className="coco__icon google action_btn__icon mr-2"/>
          <span>{ t('auth.social.btn-google') }</span>
        </Button>
      )}
      buttonText="Login"
      onSuccess={responseGoogle}
      onFailure={responseGoogle}
      cookiePolicy={'single_host_origin'}
    />
  );
};

export default AuthGoogleBtn;
import { takeEvery, put, select, call } from "redux-saga/effects";
import { push } from "connected-react-router";

import OrderRepository from "../../repositories/order";
import StripeRepository from "../../repositories/strype";
import BaseRepositoryError from "../../repositories/base.errors";
import StripeService, {
  StripeServiceError,
} from "../../../services/payments/stripe";

import { addressForOrderApi } from "../../selectors/delivery";
import { userDataForOrderApi } from "../../selectors/user";
import { menuItemsForOrderApi } from "../../selectors/order";

import {
  FETCH_ORDER_PLACE_SAGA,
  SET_DATA_ERROR_MSG,
  SET_STRIPE_CLIENT_SECRET,
  SET_ORDER_PLACED_ID,
  SET_STRIPE_ERROR_MSG,
} from "../../actions/actionTypes";
import ROUTES from "../../../routes";

function* workerOrderPlace(action) {
  // user data
  // delivery data
  // delivery data
  const addressData = yield select((state) =>
    addressForOrderApi(state, "destination", true)
  );
  const userData = yield select((state) =>
    userDataForOrderApi(state, "customer_")
  );
  const menuItemsData = yield select(menuItemsForOrderApi);
  const { id } = yield select((state) => state.restaurant.restaurantData);

  const payloadsData = {
    merchant_id: id,
    promo_code: action.payload.promoCode || " ",
    note: action.payload.note || " ",
  };
  const { paymentMethod, stripe, slug } = action.payload;

  addressData["destination"]["suite"] = action.payload && action.payload.suite;
  userData.customer_first_name = userData.customer_first_name || " ";
  userData["customer_last_name"] = userData["customer_last_name"] || " ";

  try {
    const responseOrder = yield call(OrderRepository.placeOrder, {
      addressData,
      userData,
      menuItemsData,
      payloadsData,
    });

    if (!responseOrder || !responseOrder.order_id) {
      yield put({ type: SET_DATA_ERROR_MSG, payload: "Failed to place order" });
    } else {
      payloadsData["order_id"] = responseOrder.order_id;
      const paymentAddressData = yield select((state) =>
        addressForOrderApi(state)
      );
      const responsePayment = yield call(StripeRepository.createPaymentIntent, {
        addressData: paymentAddressData,
        userData,
        menuItemsData,
        payloadsData,
      });
      yield put({ type: SET_ORDER_PLACED_ID, payload: responseOrder.order_id });
      const isPayed = yield call(
        StripeService.completePaymentIntent,
        stripe,
        responsePayment.client_secret,
        { payment_method: paymentMethod.id }
      );
      if (isPayed) {
        // redirect to thank you page
        yield put(
          push(
            `${ROUTES.ORDER.link_path(
              slug
            )}/${ROUTES.ORDER.child.TANK_YOU.link_path(responseOrder.order_id)}`
          )
        );
      } else {
        yield put({
          type: SET_STRIPE_ERROR_MSG,
          payload: "Please check the payment information",
        });
      }
    }
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put({ type: SET_DATA_ERROR_MSG, payload: e.message });
    }
    if (e instanceof StripeServiceError) {
      yield put({ type: SET_STRIPE_ERROR_MSG, payload: e.message });
    }
  }
}

export default function* watchOrderPlace() {
  yield takeEvery(FETCH_ORDER_PLACE_SAGA, workerOrderPlace);
}

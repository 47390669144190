import { SET_MENU_ITEMS_CART, SET_ORDER_CART_TOTAL } from '../../actions/actionTypes';

export const orderInitialState = {
  cartTotal: {
    crv: 0,
    delivery_fee: 0,
    discount: 0,
    final_price: 0,
    sales_tax: 0,
    subtotal_price: 0,
    total_price: 0,
  },
};

export default function orderData(state = orderInitialState, action) {
  switch (action.type) {
    case SET_ORDER_CART_TOTAL:
      return {
        ...state,
        cartTotal: action.payload,
      };
    default:
      return state;
  }
}
import { useContext } from 'react';
import { priceFormatter } from '../../../../utils';
import { Checkbox, Select } from 'antd';
import { RestaurantMenuContext  } from '../../TheRestaurantMenu/context';

const { Option } = Select;


const Options = (props) => {
  const { menuOptions } = props;
  const { addOns, handleCheckBox, handleSelect, DELETE_ITEM } = useContext(RestaurantMenuContext);

  const renderSelect = (name, ind, options) => {
    const sortedOptions = [ { name: DELETE_ITEM, added_price: 0 }, ...options.sort((a, b) => a.added_price - b.added_price)];
    const zeroPriceOption = options.find(el => el.added_price === 0);

    return (
      <Select
        defaultValue={ zeroPriceOption && [zeroPriceOption.name, zeroPriceOption.added_price]}
        style={{ width: 180 }}
        bordered={false}
        onSelect={(value) => handleSelect(name, value)}
      >
        { sortedOptions.map((children, chInx) => (
          <Option
            key={`options${ind}_${chInx}`}
            value={[children.name, children.added_price]}>
            { children.name }
            { children.name !== DELETE_ITEM &&
              <span className="color_app__primary">
                {`+${priceFormatter(children.added_price)}`}
              </span>
            }
          </Option>
        ))}
      </Select>
    );
  };

  return (
    menuOptions.map((option, ind) => (
      <div key={`menuOption${ind}`}>
        { option.children && option.children.length ?
          <div className="menu_option__item">
            <div>
              <p className="text-sm">Add {option.name}</p>
              <p className={addOns[option.name] ? 'color_app__primary' : 'app_medium_color'}>
                +{ priceFormatter(addOns[option.name] ? addOns[option.name].added_price : 0) }
              </p>
            </div>
            <div>
              {renderSelect(option.name, ind, option.children)}
            </div>
          </div> :
          <div className="menu_option__item">
            <div>
              <p className="text-sm">Add {option.name}</p>
              <p className={addOns[option.name] ? 'color_app__primary' : 'app_medium_color'}>
                +{ priceFormatter(option.added_price) }
              </p>
            </div>
            <div>
              <Checkbox
                className="app__checkbox"
                onChange={() => handleCheckBox(option)}
                checked={Boolean(addOns[option.name])}
              />
            </div>
          </div>
        }
      </div>
    ))
  );
};

export default Options;
// import { Switch, Route, Link } from 'react-router-dom';
import './App.less';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import  { message } from 'antd';
import { useMediaQuery } from 'react-responsive';

import NotFoundResult from './components/Results/404';

import Auth from './containers/Auth';
import IdVerification from './containers/IdVerification';
import Profile from './containers/Profile';
import RestaurantLanding from './containers/RestaurantLanding';
import RestaurantOrder from './containers/RestaurantOrder';

import ROUTES from './routes';
import { FETCH_DATA_ERROR_MSG, FETCH_USER_DATA_SAGA, SET_IS_MOBILE } from './redux/actions/actionTypes';

import ApiService from './services/api';

const App = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery({ query: '(max-width: 758px)' });
  const { errorMsg } = useSelector(state => state.errors);
  const userData = useSelector(state => state.user.userData);

  useEffect(() => {
    ApiService.mount401Interceptor();
    if (userData.id) {
      dispatch({type: FETCH_USER_DATA_SAGA });
    }
  }, []);

  useEffect(() => {
    if (errorMsg) {
      message.warning(errorMsg);
      dispatch({type: FETCH_DATA_ERROR_MSG, payload: '' });
    }
  }, [errorMsg]);

  useEffect(() => {
    dispatch({type: SET_IS_MOBILE, payload: isMobile });
  }, [isMobile]);

  return (
    <Switch>
      <Route exact path={ROUTES.RESTAURANT_LANDING.path}>
        <RestaurantLanding/>
      </Route>
      <Route path={ROUTES.AUTH.path}>
        <Auth/>
      </Route>
      <Route path={ROUTES.PROFILE.path}>
        <Profile/>
      </Route>
      <Route path={ROUTES.ORDER.path}>
        <RestaurantOrder/>
      </Route>
      <Route path={ROUTES.ID_VERIFICATION.path}>
        <IdVerification/>
      </Route>
      <Redirect exact from="/" to={ROUTES.RESTAURANT_LANDING.defaultRedirect} />
      <Route path={ROUTES.NOT_FOUND.path}>
        <NotFoundResult/>
      </Route>
    </Switch>
  );
};

export default App;

import {
  SET_SLUG_RESTAURANT,
} from '../actions/actionTypes';

const initialState = {
  restaurantSlug: null,
};


export default function rootData(state = initialState, action) {
  switch (action.type) {
    case SET_SLUG_RESTAURANT:
      return {
        ...state,
        restaurantSlug: action.payload,
      };
    default:
      return state;
  }
}
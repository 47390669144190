import {
  SET_DATA_ERROR_MSG,
} from '../actions/actionTypes';

const initialState = {
  errorMsg: null,
};

export default function setErrors(state = initialState, action) {
  switch (action.type) {
    case SET_DATA_ERROR_MSG:
      return {
        ...state,
        errorMsg: action.payload,
      };
    default:
      return state;
  }
}
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, Input, Row, Select } from 'antd';

import PlacesAutocomplete from './PlacesAutocomplete';


const ProfileForm = (props) => {
  const { t } = useTranslation();
  const { formLayout, userInitialData, onFinish, btnText, isPassword } = props;
  const [ addressObj, setAddressObj ] = useState({});
  
  const handleFinish = (values) => {
    const { phone, phoneCode, first_name, last_name, password, email, suite } = values;
    // merge address with rest of the form
    onFinish({ phone: `${phoneCode}${phone}`, first_name, last_name, email, password, addressObj, suite });
  };

  const handleAddress = (value) => {
    setAddressObj({ ...value });
  };

  return (
    <Form
      className="auth__form"
      layout={ formLayout }
      size={ 'large' }
      initialValues={ userInitialData }
      onFinish={ handleFinish }
    >
      <Row gutter={ 18 }>
        <Col span={ 12 } sm={ {order: 24} } md={ {order: 12} }>
          <Form.Item name={ 'first_name' } label="First name" rules={ [{required: true}] }>
            <Input/>
          </Form.Item>
        </Col>
        <Col span={ 12 } sm={ {order: 24} } md={ {order: 12} }>
          <Form.Item name={ 'last_name' } label="Last name" rules={ [{required: true}] }>
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Email"
        name="email"
        rules={ [
          {required: true, message: 'Please input your email!'},
          {type: 'email', message: 'Please input email!'},
        ] }
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label="Address"
        name="address"
      >
        <PlacesAutocomplete
          className={"s"}
          initialValues={ userInitialData.addressLine }
          onPlaceGet={ handleAddress }
          isAllowClear={true}
        />
      </Form.Item>
      <Form.Item
        label="Suite"
        name="suite"
      >
        <Input placeholder="suite"/>
      </Form.Item>
      <Row gutter={ 16 }>
        <Col span={ 7 } sm={ {order: 7} } md={ {order: 7} }>
          <Form.Item name={ 'phoneCode' } label="Country" rules={ [{required: true}] }>
            <Select value="+1">
              <Select.Option value="+1">+1(US)</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={ 17 } sm={ {order: 17} } md={ {order: 17} }>
          <Form.Item name={ 'phone' } label="Mobile Number">
            <Input/>
          </Form.Item>
        </Col>
      </Row>
      { isPassword &&
        <Form.Item
          label="Password"
          name="password"
          rules={ [{required: true, message: 'Please input your password!'}] }
        >
          <Input.Password/>
        </Form.Item>
      }
      <Form.Item label=" " colon={ false }>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          className="auth_btn app__btn text-upper" block
        >
          { btnText }
        </Button>
      </Form.Item>
    </Form>
  );
};

PlacesAutocomplete.defaultProps = {
  userInitialData: {},
  isPassword: false,
};

export default ProfileForm;
import { put, takeLatest, call } from "redux-saga/effects";
import BaseRepositoryError from '../../repositories/base.errors';
import AuthRepository from '../../repositories/auth';

import {
  FETCH_REGISTER_SAGA,
  FETCH_DATA_404,
  SET_DATA_ERROR_MSG,
  SET_USER_DATA,
  FETCH_ADD_PHONE_SAGA,
  FETCH_UPDATE_USER_DATA_SAGA,
} from '../../actions/actionTypes';

function* workerFetchSignUp(action) {
  try {
    const { phone, first_name, last_name, email, password, suite, addressObj } = action.payload;
    const response = yield call(AuthRepository.register, {  phone, first_name, last_name, email, password });
    yield put({ type: SET_USER_DATA, payload: response.user });
    // update for add address
    yield put({
      type: FETCH_UPDATE_USER_DATA_SAGA,
      payload: { phone, first_name, last_name, email, suite, addressObj, user_id: response.user.id },
    });
    // FETCH_UPDATE_USER_DATA_SAGA should cover logic for default delivery address
    if (response.user && phone) {
      yield put({ type: FETCH_ADD_PHONE_SAGA, payload: { phone, user_id: response.user.id } });
    } else {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: 'please try again'});
    }
  } catch (e) {
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* WatchLogout() {
  yield takeLatest(FETCH_REGISTER_SAGA, workerFetchSignUp);
}

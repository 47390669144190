import { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ProfileForm from '../../components/Form/ProfileForm';

import {
  FETCH_UPDATE_USER_DATA_SAGA,
} from '../../redux/actions/actionTypes';

const ProfileUpdate = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [formLayout, setFormLayout] = useState('vertical');
  const [loading, setLoading] = useState(false);
  const { errorMsg } = useSelector(state => state.errors);
  
  const [ contactUserInitialData, setContactUserInitialData ] = useState();
  const { userData } = useSelector(state => state.user);

  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  
  const fetchUpdateUserData = useCallback(
    (payload) => dispatch({ type: FETCH_UPDATE_USER_DATA_SAGA,  payload }),
    [dispatch],
  );

  useEffect(() => {
    setLoading(false);
    if (userData) {
      setContactUserInitialData({
        phoneCode: userData.phone ? userData.phone.slice(0, 2) : '+1',
        ...userData,
        phone: userData.phone && userData.phone.slice(2),
        suite: userData.address && userData.address.suite,
        addressLine: userData.address && `${userData.address.address1}, ${userData.address.address2}`
      });
    }
  }, [userData]);

  useEffect(() => {
    if (errorMsg) {
      setLoading(false);
    }
  }, [errorMsg]);
  
  const handleFinish = (values) => {
    setLoading(true);
    fetchUpdateUserData(values);
  };

  return(
    <div>
      <Helmet>
        <title>{ t('profile.profile-update.header') }</title>
      </Helmet>
      <h1 className="mb-2">
        { t('profile.profile-update.header') }
      </h1>
      { contactUserInitialData &&
      <Spin spinning={ loading } indicator={ antIcon }>
        <ProfileForm
          formLayout={formLayout}
          userInitialData={contactUserInitialData}
          onFinish={handleFinish}
          btnText={ t('profile.profile-update.save-btn') }
        />
      </Spin> }
    </div>
  );
};

export default ProfileUpdate;
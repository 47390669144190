import { put, takeLatest, call, select } from "redux-saga/effects";
import BaseRepositoryError from '../../repositories/base.errors';
import AuthRepository from '../../repositories/auth';
import { push } from 'connected-react-router';
import ROUTES from '../../../routes';

import {
  SET_DATA_ERROR_MSG,
  FETCH_RESET_PASSWORD_SAGA,
  FETCH_DATA_404,
} from '../../actions/actionTypes';

function* workerFetchResetPassword(action) {
  try {
    yield call(AuthRepository.resetPassword,{ email: action.payload.email });
    yield put(push(
      { pathname: `${ROUTES.AUTH.path}/${ROUTES.AUTH.child.RESET_PASSWORD_SENT.path}`}
    ));
  } catch (e) {
    console.debug(e);
    if (e instanceof BaseRepositoryError) {
      yield put ({ type: SET_DATA_ERROR_MSG, payload: e.message });
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* watchFetchResetPassword() {
  yield takeLatest(FETCH_RESET_PASSWORD_SAGA, workerFetchResetPassword);
}

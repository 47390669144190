
const ParseAutoplacedAddressLine = (addressLine) => {
  const result = {
    address1: '',
    address2: '',
  };
  
  if (!addressLine) {
    return result;
  }
  const arrAddress = addressLine.split(',');
  if (arrAddress.length > 3 ) {
    result.address1 = arrAddress.slice(0, 1).join('');
    result.address2 = arrAddress.slice(1).join(',');
  } else {
    result.address1 = addressLine;
  }
  return result;
};

export default ParseAutoplacedAddressLine;
import {
  SET_ORDER_PLACED_ID,
  SET_ORDER_PLACED_DATA,
} from '../../actions/actionTypes';

export const orderPlacedInitialState = {
  orderPlacedId: null,
  orderPlacedData: {},
};

export default function setUserData(state = orderPlacedInitialState, action) {
  switch (action.type) {
    case SET_ORDER_PLACED_ID:
      return {
        ...state,
        orderPlacedId: action.payload,
      };
    case SET_ORDER_PLACED_DATA:
      return {
        ...state,
        orderPlacedData: action.payload,
      };
    default:
      return state;
  }
}
import ApiService from '../../services/api';
import TokenService from '../../services/storage/token';
import BaseRepositoryError from './base.errors';

const ServiceRepository = {

  async getServiceInfo(payload) {
    const params = {
      ...payload.lat && { latitude: payload.lat },
      ...payload.lng && { longitude: payload.lng }
    };

    const requestData = {
      method: 'get',
      url: '/service/info',
      params,
    };

    try {
      const response = await ApiService.customJsonRequest(requestData);
      return response.data;
    } catch (error) {
      throw new BaseRepositoryError(error.response.status, error.response);
    }
  },
};

export default ServiceRepository;

export { ServiceRepository, BaseRepositoryError };

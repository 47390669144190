import axios from "axios";
import TokenService from "../storage/token";
import store from "../../redux/store";
import { FETCH_LOGOUT_SAGA } from "../../redux/actions/actionTypes";

const ApiService = {
  // Stores the 401 interceptor position so that it can be later ejected when needed
  interceptor_401: null,
  refreshUrl: "/auth/refresh",

  init(baseURL) {
    axios.defaults.baseURL = baseURL;
  },

  mount401Interceptor() {
    this.interceptor_401 = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.request.status === 401) {
          if (error.config.url.includes("auth")) {
            // Refresh token has failed or auth endpoint return 401 => Logout the user
            store.dispatch({ type: FETCH_LOGOUT_SAGA });
            throw error;
          } else {
            // refresh token
            try {
              const originalRequest = error.config;
              const response = await this.customJsonRequest({
                method: "get",
                url: this.refreshUrl,
              });
              await TokenService.saveToken(response.data.access_token);
              originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;
              return axios(originalRequest);
            } catch (e) {
              // Refresh has failed - reject the original request
              throw error;
            }
          }
        }
        // If error was not 401 just reject as is
        throw error;
      }
    );
  },

  unmount401Interceptor() {
    // Eject the interceptor
    axios.interceptors.response.eject(this.interceptor_401);
  },

  setAuthHeader() {
    axios.defaults.headers.common.Authorization = `Bearer ${TokenService.getToken()}`;
  },

  setCustomAuthHeader(key) {
    axios.defaults.headers.common.Authorization = key;
  },

  setContentType(type) {
    axios.defaults.headers["content-type"] = type;
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource) {
    return axios.get(resource);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  /*
   * Perform a custom Axios request.
   *
   * data is an object containing the following properties:
   *  - method
   *  - url
   *  - data ... request payload
   *  - auth (optional)
   *    - username
   *    - password
   */

  customJsonRequest(data) {
    this.setAuthHeader();
    this.setContentType("application/json");
    return axios(data);
  },

  customRequest(data) {
    return axios(data);
  },
};

export default ApiService;

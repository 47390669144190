import { put, takeLatest, call } from "redux-saga/effects";

import {
  FETCH_UPDATE_RESTRICTED_ORDER_DATA,
  FETCH_DATA_404,
  SET_DATA_ERROR_MSG,
  SET_UPDATE_RESTRICTED_ORDER_DATA,
} from "../../actions/actionTypes";

import BaseRepositoryError from "../../repositories/base.errors";
import RestrictedRepository from "../../repositories/restricted";

function* workerUpdateRestrictedOrderData(action) {
  try {
    const {
      agreed_to_terms,
      back_id,
      date_of_birth,
      front_id,
      need_id_upload,
      orderId,
    } = action.payload;
    const response = yield call(RestrictedRepository.setOrderData, orderId, {
      agreed_to_terms,
      back_id,
      date_of_birth,
      front_id,
      need_id_upload,
    });

    yield put({ type: SET_UPDATE_RESTRICTED_ORDER_DATA, payload: response });
  } catch (e) {
    if (e instanceof BaseRepositoryError) {
      yield put({ type: SET_DATA_ERROR_MSG, payload: e.message });
      if (e.errorCode === 404) {
        yield put({ type: FETCH_DATA_404 });
      }
    }
  }
}

export default function* watchUpdateRestrictedOrderData() {
  yield takeLatest(
    FETCH_UPDATE_RESTRICTED_ORDER_DATA,
    workerUpdateRestrictedOrderData
  );
}
